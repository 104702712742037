import {Servicio} from "../../types/servicio";
import {Accordion, Button, Card, Col, Container, Row, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useAccordionButton} from "react-bootstrap/AccordionButton";
import {formatoFechaTiempo, formatoMoneda, SUCCESS} from "../../utils";
import {aceptarCotizacion, rechazarCotizacion, reenviarCorreo} from "../../services/conem-services";
import ModalMessage from "../common/ModalMessage";
import Loader from "../common/Loader";
import ConfirmacionModal from "../common/ConfirmacionModal";
import FormEdicionCotizacion from "./FormEdicionCotizacion";
import {Children} from 'react';

export default function CotizacionItem(props: {
    cotizacion: Servicio,
    index: number,
    selectedIndex: number,
    editable: boolean,
    onSelect: (index: number) => void,
    onEdit: (index: number) => void,
    onUpdate: (e: Servicio) => void;
    actualizar: () => void
}) {

    const [status, setStatus] = useState<string>('');
    const [fechaEnviado, setFechaEnviado] = useState<string>('');

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState<string>('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);


    const [confirmarAceptarCotizacion, setConfirmarAceptarCotizacion] = useState<boolean>(false);
    const [confirmarRechazarCotizacion, setConfirmarRechazarCotizacion] = useState<boolean>(false);
    const [confirmarReenviarCorreo, setConfirmarReenviarCorreo] = useState<boolean>(false);

    const [sendingEmail, setSendingEmail] = useState<boolean>(false);
    const [downloadingFile, setDownloadingFile] = useState<boolean>(false);


    useEffect(() => {
        setStatus(props.cotizacion.status);
        // @ts-ignore
        setFechaEnviado(props.cotizacion.fechaEnviado);
    }, [props.cotizacion]);

    // @ts-ignore
    function RowCustomToggle({eventKey, children}) {
        const [open, setOpen] = useState<boolean>(props.index === props.selectedIndex);
        const decoratedOnClick = useAccordionButton(eventKey, (e) => {
            setOpen(!open);
            props.onSelect(parseInt(eventKey));
            props.onEdit(-1);
        });
        return (
            <Row onClick={decoratedOnClick} style={{cursor: 'pointer'}}>
                {children}
            </Row>
        );
    }

    const LabelTipoCliente = (tipoCliente: string) => {
        let estilo = "success";
        let tipoClienteText = "Nuevo";
        if (tipoCliente === "renovacion") {
            estilo = "done";
            tipoClienteText = "Renovación";
        }
        return <Container className={`text-center rounded conem-label conem-bg-${estilo}`}>
            <span>Tipo de cliente: </span><span className="fw-bold">{tipoClienteText}</span>
        </Container>

    }

    const LabelNivelRiesgo = (riesgo: string) => {
        let estilo = "success";
        let riesgoText = "Bajo";
        if (riesgo === "medio") {
            estilo = "warning";
            riesgoText = "Medio";
        } else if (riesgo === "alto") {
            estilo = "danger";
            riesgoText = "Alto";
        }
        return <Container className={`text-center rounded conem-label conem-bg-${estilo}`}>
            <span>Nivel de riesgo: </span><span className="fw-bold">{riesgoText}</span>
        </Container>
    }

    const LabelStatusCotizacion = (status: string) => {
        let textStatus = "Guardado";
        if (status === 'enviado') {
            textStatus = 'Enviado';
        } else if (status === 'rechazado') {
            textStatus = 'Rechazado';
        } else if (status === 'expirado') {
            textStatus = 'Expirado';
        }
        return <div className={`conem-label rounded`} style={{color: '#3a3939'}}>
            <label>Status de la cotización: <span className="fw-bold"
                                                  style={{fontSize: "0.9em"}}>{textStatus}</span></label>
        </div>

    }

    const mostrarMensajeExito = (message: string) => {
        setError(false);
        setMessage(message);
        setShowMessage(true);
    }

    const mostrarMensajeError = (message: string) => {
        setError(true);
        setMessage(message);
        setShowMessage(true);
    }

    const showFormEdicion = () => {
        return <FormEdicionCotizacion
            cotizacion={props.cotizacion}
            onClose={() => {
                props.onEdit(-1);
            }}
            onUpdate={props.onUpdate}
        />
    }

    const showPanelDetails = () => {
        return <>
            <Accordion.Collapse eventKey={props.index.toString()}>
                <Card.Body>
                    <Row>
                        <Col className="col-7">
                            <Row>
                                <Col>
                                    {
                                        LabelNivelRiesgo(props.cotizacion.cliente?.detalle?.nivelRiesgo)
                                    }
                                </Col>
                                <Col>
                                    {
                                        LabelTipoCliente(props.cotizacion.cliente?.detalle?.tipoCliente)
                                    }
                                </Col>
                            </Row>
                            <Container style={{marginTop: "25px"}}>
                                <Row>
                                    <Col>
                                        <span>Grupo de atención:</span>
                                        <span className="fw-bold">{props.cotizacion.grupo?.nombre}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><span>Servicio:</span> <span
                                        className="fw-bold">{props.cotizacion.descripcion}</span></Col>
                                </Row>
                                <Row>
                                    <Col><span>Correo de contacto:</span> <span
                                        className="fw-bold">{props.cotizacion.cliente.correo}</span></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span>Teléfono de contacto:</span> <span
                                        className="fw-bold">{props.cotizacion.cliente.telefono}</span></Col>
                                </Row>
                                <Row>
                                        <span>Requiere Factura: <span
                                            className="fw-bold">{props.cotizacion.requiereFactura === true ? 'Sí' : 'No'}</span></span>
                                </Row>
                            </Container>
                        </Col>
                        <Col>

                            {
                                (status === 'guardado' || status === 'enviado') &&
                                <div className='d-flex justify-content-end'>
                                    <Button size='sm' variant='primary' style={{width: '80%'}} disabled={sendingEmail}
                                            onClick={() => {
                                                setConfirmarReenviarCorreo(true);
                                            }}>
                                        {
                                            sendingEmail ?
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    <span>Enviando correo...</span>
                                                </> :
                                                <><i className="bi bi-envelope-plus"></i> Reenviar correo al cliente</>
                                        }
                                    </Button>
                                </div>
                            }
                            {

                                ((status === 'guardado' || status === 'enviado') && props.cotizacion.documentoCotizacion?.url) &&
                                <div className='d-flex justify-content-end' style={{marginTop: '15px'}}>
                                    <Button size='sm' variant='secondary' style={{width: '80%'}}
                                            disabled={downloadingFile}
                                            onClick={() => {
                                                if (props.cotizacion.documentoCotizacion?.url) {
                                                    setDownloadingFile(true);
                                                    fetch(props.cotizacion.documentoCotizacion.url)
                                                        .then(response => {
                                                            response.blob().then(blob => {
                                                                let url = window.URL.createObjectURL(blob);
                                                                let a = document.createElement('a');
                                                                a.href = url;
                                                                a.download = `${props.cotizacion.folio}.pdf`;
                                                                a.click();
                                                            });
                                                        }).finally(() => {
                                                        setDownloadingFile(false);
                                                    });
                                                }
                                            }}>
                                        {
                                            downloadingFile ?
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    <span>Descargando documento...</span>
                                                </> :
                                                <><i className="bi bi-filetype-pdf"></i> Descargar documento</>
                                        }
                                    </Button>
                                </div>
                            }
                            {

                                (status === 'guardado' || status === 'enviado'  || status === 'rechazado') &&
                                <div className='d-flex justify-content-end' style={{marginTop: '15px'}}>
                                    <Button size='sm' variant='warning' style={{width: '80%'}}
                                            onClick={() => props.onEdit(props.index)}>
                                        <i className="bi bi-pencil-fill"></i> Editar cotización
                                    </Button>
                                </div>
                            }
                            {
                                (status === 'guardado' || status === 'enviado') &&
                                <div className='d-flex justify-content-end' style={{marginTop: '15px'}}>
                                    <Button size='sm' variant='success' style={{width: '80%'}}
                                            onClick={async () => {
                                                setConfirmarAceptarCotizacion(true);
                                            }}>
                                        <i className="bi bi-envelope-check"></i> Aceptar cotización
                                    </Button>
                                </div>
                            }
                            {
                                (status === 'guardado' || status === 'enviado') &&
                                <div className='d-flex justify-content-end' style={{marginTop: '15px'}}>
                                    <Button size='sm' variant='danger' style={{width: '80%'}}
                                            onClick={async () => {
                                                setConfirmarRechazarCotizacion(true);
                                            }}>
                                        <i className="bi bi-envelope-slash"></i> Rechazar cotización
                                    </Button>
                                </div>
                            }
                        </Col>
                    </Row>
                    <hr/>
                    <Container>
                        <Row>
                            <Col className="col-9">
                                <Row>
                                        <span>Nombre comercial: <span
                                            className="fw-bold">{props.cotizacion.cliente?.detalle?.nombreComercial}</span></span>
                                </Row>
                                <Row>
                                        <span>Dirección exacta del inmueble: <span
                                            className="fw-bold">{props.cotizacion.cliente?.detalle?.direccion}</span></span>
                                </Row>
                                <Row>
                                        <span>Giro: <span
                                            className="fw-bold">{props.cotizacion.cliente?.detalle?.giro}</span></span>
                                </Row>
                                <Row>
                                        <span>Razón Social: <span
                                            className="fw-bold">{props.cotizacion.cliente?.detalle?.razonSocial}</span></span>
                                </Row>
                                <Row>
                                        <span>RFC: <span
                                            className="fw-bold">{props.cotizacion.cliente?.detalle?.rfc}</span></span>
                                </Row>
                                <Row>
                                        <span>Domicilio Fiscal: <span
                                            className="fw-bold">{props.cotizacion.cliente?.detalle?.domicilioFiscal}</span></span>
                                </Row>

                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <div className='d-flex justify-content-end'>
                                            <span className="fs-5 fw-bold">Precio:</span>
                                            <span
                                                className="fs-5 fw-bold">{formatoMoneda(props.cotizacion.precio)}</span>
                                        </div>
                                    </Col>
                                </Row>
                                {
                                    (props.cotizacion.descuento > 0) &&
                                    <Row>
                                        <Col>
                                            <div className='d-flex justify-content-end'>
                                                     <span
                                                         className="fs-5 fw-bold">Desc.:</span>
                                                <span
                                                    className="fs-5 fw-bold"
                                                    style={{textDecoration: 'line-through'}}>{formatoMoneda(props.cotizacion.descuento)}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col>

                                        <div className='d-flex justify-content-end'>
                                                <span
                                                    className="fs-5 fw-bold">IVA:</span>
                                            <span
                                                className="fs-5 fw-bold">{formatoMoneda(props.cotizacion.iva)}</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>

                                        <div className='d-flex justify-content-end'>
                                                <span
                                                    className="fs-5 fw-bold">Total:</span>
                                            <span
                                                className="fs-5 fw-bold">{formatoMoneda(props.cotizacion.total)}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Accordion.Collapse>
        </>
    }

    return (
        <>
            <Loader show={loading}/>
            <Card>
                <Card.Header className="cotizacion-list-item-header">


                    <RowCustomToggle eventKey={props.index.toString()}>

                        <Col className="col-5">
                            <div>
                                Folio: <span className="fw-bold"
                                             style={{fontSize: "14px"}}>{props.cotizacion.folio}</span>
                            </div>
                            {
                                LabelStatusCotizacion(status)
                            }
                        </Col>
                        <Col sm='3'>
                            <div>
                                Contacto:
                            </div>
                            <div>
                                <span className="fw-bold"> {props.cotizacion.cliente?.nombreCompleto}</span>
                            </div>
                        </Col>
                        <Col sm='3'>
                            <div className="d-flex justify-content-end">
                                <label>Registrado: <span className="fw-bold" style={{
                                    fontSize: "0.9em",
                                    color: '#3a3939'
                                }}>{formatoFechaTiempo(props.cotizacion.fechaRegistro ? props.cotizacion.fechaRegistro : null)}</span>
                                </label>
                            </div>
                            {
                                fechaEnviado &&
                                <div className="d-flex justify-content-end"><label>Enviado: <span className="fw-bold"
                                                                                                  style={{
                                                                                                      fontSize: "0.9em",
                                                                                                      color: '#3a3939'
                                                                                                  }}>{formatoFechaTiempo(fechaEnviado)}</span></label>
                                </div>
                            }
                        </Col>
                    </RowCustomToggle>

                </Card.Header>
                {
                    props.editable && showFormEdicion()
                }
                {
                    !props.editable && showPanelDetails()
                }
            </Card>

            <ModalMessage show={showMessage} close={() => setShowMessage(false)} error={error} message={message}/>
            {/*modal para confirmar correo*/}
            <ConfirmacionModal show={confirmarReenviarCorreo} title='Confirmación'
                               onAccept={async () => {
                                   if (props.cotizacion.grupoId) {
                                       setConfirmarReenviarCorreo(false);
                                       setSendingEmail(true);
                                       reenviarCorreo(props.cotizacion.grupoId).then(response => {
                                           if (response.codigo === SUCCESS) {
                                               props.actualizar();
                                           } else {
                                               mostrarMensajeError(response.mensaje);
                                           }
                                       }).catch(err => {
                                           mostrarMensajeError(`Lo sentimos ha ocurrido un error al enviar el correo a ${props.cotizacion.cliente.correo}`)
                                       }).finally(() => {
                                           setSendingEmail(false);
                                       });
                                   }
                               }}
                               onCancel={() => {
                                   setConfirmarReenviarCorreo(false);
                               }}
                               message={[`¿Está seguro de reenviar el correo de la cotización ${props.cotizacion.folio} al cliente ${props.cotizacion.cliente.correo}?`]}/>

            {/*modal para confirmar aceptar cotización*/}
            <ConfirmacionModal show={confirmarAceptarCotizacion} title='Confirmación'
                               onAccept={async () => {
                                   if (props.cotizacion.id) {
                                       setLoading(true);
                                       aceptarCotizacion(props.cotizacion.id).then(response => {
                                           if (response.codigo === SUCCESS) {
                                               props.actualizar();
                                           } else {
                                               mostrarMensajeError(response.mensaje);
                                           }
                                       }).catch(error => {
                                           mostrarMensajeError(`Lo sentimos ha ocurrido un error al actualizar la cotización ${props.cotizacion.folio}`)
                                       }).finally(() => {
                                           setConfirmarAceptarCotizacion(false);
                                           setLoading(false);
                                       })
                                   }
                               }}
                               onCancel={() => {
                                   setConfirmarAceptarCotizacion(false);
                               }}
                               message={[`¿Está seguro de aceptar la cotización ${props.cotizacion.folio}?`]}/>

            {/*modal para confirmar rechazar cotización*/}
            <ConfirmacionModal show={confirmarRechazarCotizacion} title='Confirmación'
                               onAccept={async () => {

                                   if (props.cotizacion.id) {
                                       setLoading(true);
                                       rechazarCotizacion(props.cotizacion.id).then(response => {
                                           if (response.codigo === SUCCESS) {
                                               setStatus('rechazado');
                                           } else {
                                               mostrarMensajeError(response.mensaje);
                                           }
                                       }).catch(error => {
                                           mostrarMensajeError(`Lo sentimos ha ocurrido un error al actualizar la cotización ${props.cotizacion.folio}`)
                                       }).finally(() => {
                                           setConfirmarRechazarCotizacion(false);
                                           setLoading(false);
                                       })
                                   }
                               }}
                               onCancel={() => {
                                   setConfirmarRechazarCotizacion(false);
                               }}
                               message={[`¿Está seguro de rechazar la cotización ${props.cotizacion.folio}?`]}/>
        </>
    )
}