import {Container} from "react-bootstrap";
import React from "react";

export default function MensajeResultadoVacio() {
    return (
        <Container className="rounded text-xl-center dashed-box d-flex justify-content-center align-items-center">
            <div>
                <label className='fs-2 fw-bold' style={{color: '#d0cece'}}>No se encontraron registros</label>
            </div>
        </Container>
    )
}