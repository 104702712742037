import React from "react";
import SubmenuItem from "./SubmenuItem";
import {isAdmin} from "../../utils";

export default function Sidebar(
    props: {
        selectedMenuItem: string,
        onChangeSelectedMenuItem: (e: string) => void
    }
    ) {
    return (
        <>
            <div id="sidebar-container" className="sidebar-expanded col-sm-2 bg-dark">
                <ul className="list-group" style={{border: '0px'}}>
                    <SubmenuItem icon='bi bi-speedometer2' text="Estadísticas"
                                 selected={props.selectedMenuItem === 'charts'} onSelect={() => {
                        props.onChangeSelectedMenuItem('charts');
                    }}/>

                    <SubmenuItem icon='bi bi-newspaper' text="Nueva cotización"
                                 selected={props.selectedMenuItem === 'cotizacion'} onSelect={() => {
                        props.onChangeSelectedMenuItem('cotizacion');
                    }}/>

                    <SubmenuItem icon='bi bi-file-spreadsheet' text="Cotizaciones"
                                 selected={props.selectedMenuItem === 'consultas'} onSelect={() => {
                        props.onChangeSelectedMenuItem('consultas');
                    }}/>

                    <SubmenuItem icon='bi bi-list-check' text="Servicios"
                                 selected={props.selectedMenuItem === 'servicios'} onSelect={() => {
                        props.onChangeSelectedMenuItem('servicios');
                    }}/>
                    <SubmenuItem icon='bi bi-list' text="Catálogo de servicios"
                                 selected={props.selectedMenuItem === 'confplan'} onSelect={() => {
                        props.onChangeSelectedMenuItem('confplan');
                    }}/>
                    {
                        isAdmin() && <SubmenuItem icon='bi bi-people-fill' text="Usuarios"
                                                  selected={props.selectedMenuItem === 'usuarios'} onSelect={() => {
                            props.onChangeSelectedMenuItem('usuarios');
                        }}/>
                    }

                    {
                        isAdmin() && <SubmenuItem icon='bi bi-dropbox' text="Cuenta de dropbox"
                                                  selected={props.selectedMenuItem === 'dropbox'} onSelect={() => {
                            props.onChangeSelectedMenuItem('dropbox');
                        }}/>
                    }

                    {
                        isAdmin() && <SubmenuItem icon='bi bi-envelope-fill' text="Correos"
                                                  selected={props.selectedMenuItem === 'eventos'} onSelect={() => {
                            props.onChangeSelectedMenuItem('eventos');
                        }}/>
                    }
                </ul>
            </div>

        </>
    )
}
