import {Alert, Button, Container, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {getUsuarioSesion, isContraseniaValida, isRequeridoCambioContrasenia, SUCCESS} from "../../utils";
import {cambiarContrasenia, cerrarSesion} from "../../services/conem-services";
import {omit} from "lodash";


export default function FormNuevaContrasenia() {

    const [actualizado, setActualizado] = useState<boolean>(false);
    const [respuesta, setRespuesta] = useState<string>('');

    const [contrasenia1, setContrasenia1] = useState<string>('');
    const [contrasenia2, setContrasenia2] = useState<string>('');

    const [enviando, setEnviando] = useState<boolean>(false);
    const [errores, setErrores] = useState<any>({});

    useEffect(() => {
        setRespuesta('');
        setEnviando(false);
        setContrasenia1('');
        setContrasenia2('');
    }, []);

    function submit(event: any) {
        event.preventDefault();
        if (isRequeridoCambioContrasenia()) {
            setEnviando(true);
            cambiarContrasenia(getUsuarioSesion().id, contrasenia1).then(resp => {
                if (resp.codigo === SUCCESS) {
                    cerrarSesion();
                    setActualizado(true);
                } else {
                    setRespuesta(resp.mensaje)
                }
            }).catch(err => {
                setRespuesta("Ha ocurrido un error al intentar actualizar la contraseña");
            }).finally(() => {
                setEnviando(false);
            })
        } else {
            //usuario anonimo
        }
    }

    return (
        <Container style={{
            width: "30rem", padding: "30px",
            border: "1px solid rgb(204, 201, 201)",
            borderRadius: "25px", backgroundColor: "#FAFAFA"
        }}>
            {
                !actualizado && <>
                    <h4>Actualizar contraseña</h4>
                    <hr/>
                    <form id="cambiarContrasenia" onSubmit={submit}>
                        <div className="form-outline mb-lg-auto">
                            <label className="form-label" htmlFor="contrasenia1">
                                Nueva contraseña
                            </label>
                            <Form.Control type="password" isInvalid={!!errores.contrasenia1} id="contrasenia1" required
                                          onPaste={(e: any) => {
                                              e.preventDefault();
                                              return false;
                                          }}
                                          className='form-control'
                                          name="contrasenia1" value={contrasenia1}
                                          onChange={(e) => {
                                              setContrasenia1(e.target.value);
                                              if (e.target.value) {
                                                  if (e.target.value === contrasenia2) {
                                                      setErrores(omit(errores, 'contrasenia2'));
                                                  } else if (contrasenia2) {
                                                      setErrores({...errores, contrasenia2: 'La contraseñas no coinciden'});
                                                  }
                                              }
                                          }}
                                          onBlur={async (e) => {
                                              if (!isContraseniaValida(contrasenia1)) {
                                                  setErrores({
                                                      ...errores,
                                                      contrasenia1: 'La contraseña proporcionada no cumple con la pólitica requerida'
                                                  });
                                              } else {
                                                  setErrores(omit(errores, 'contrasenia1'));
                                              }
                                          }}/>
                            <Form.Control.Feedback
                                type="invalid">{errores.contrasenia1}</Form.Control.Feedback>
                        </div>
                        <div className="form-outline mb-lg-auto">
                            <label className="form-label" htmlFor="contrasenia2">
                                Confirmar nueva contraseña
                            </label>
                            <Form.Control type="password" isInvalid={!!errores.contrasenia2} id="contrasenia2" required
                                          onPaste={(e: any) => {
                                              e.preventDefault();
                                              return false;
                                          }}
                                          className='form-control'
                                          name="contrasenia2" value={contrasenia2}
                                          onChange={(e) => {
                                              setContrasenia2(e.target.value)
                                              if (contrasenia1 && e.target.value) {
                                                  if (contrasenia1 !== e.target.value) {
                                                      setErrores({...errores, contrasenia2: 'La contraseñas no coinciden'});
                                                  } else {
                                                      setErrores(omit(errores, 'contrasenia2'));
                                                  }
                                              }
                                          }}/>
                            <Form.Control.Feedback type="invalid">{errores.contrasenia2}</Form.Control.Feedback>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <Alert key="error" variant="danger" show={respuesta !== ''}>
                                {respuesta}
                            </Alert>
                        </div>
                        <div style={{marginTop: '10px'}}>
                            <ul>
                                <li className='password-policy-text'>
                                    La longitud de la contraseña deber igual o mayor a 8 caracteres
                                </li>
                                <li className='password-policy-text'>
                                    Debe contener al menos una letra minúscula
                                </li>
                                <li className='password-policy-text'>
                                    Debe contener al menos una letra mayúscula
                                </li>
                                <li className='password-policy-text'>
                                    Debe contener al menos un número
                                </li>
                                <li className='password-policy-text'>
                                    Debe contener al menos un caracter especial: [‘@’, ‘$’, ‘.’, ‘#’, ‘!’, ‘%’,
                                    ‘*’, ‘?’, ‘&’, ‘^’]
                                </li>
                            </ul>
                        </div>

                        <div className="row mb-10" style={{marginTop: "25px"}}>
                            <div className="text-center">
                                <Button variant='dark' size='sm' type="submit"
                                        className="btn btn-primary btn-block mb-4"
                                        disabled={enviando || (Object.keys(errores).length !== 0)}>
                                    {(enviando &&
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>)}
                                    {
                                        enviando ? <> Actualizando contraseña...</> :
                                            <>Actualizar contraseña</>
                                    }
                                </Button>
                            </div>
                        </div>
                    </form>
                </>
            }
            {
                actualizado &&
                <>
                    <div style={{marginTop: "10px"}}>
                        <Alert key="success" variant="success">
                            <span className='bi bi-check-circle-fill'></span><span>Su contraseña se ha actualizado correctamente por favor inicie sesión con la nueva contraseña</span>
                        </Alert>
                    </div>
                    <div className="row mb-10" style={{marginTop: "25px"}}>
                        <div className="text-center">
                            <Button variant='primary' size='sm' type="submit" style={{width: "11rem"}}
                                    className="btn btn-primary btn-block mb-4">
                                <a href='/home' style={{color: 'white'}}>Iniciar sesión</a>
                            </Button>
                        </div>
                    </div>
                </>
            }
        </Container>
    )
}