import {Accordion, Button, Card, Col, Container, Form, Row, Table} from "react-bootstrap";

import TotalLabel from "./TotalLabel";
import React, {useEffect, useRef, useState} from "react";
import {
    buscarClienteByCorreo,
    generarFolioCliente,
    getCatalogoServicios,
    getConfiguracionSistema,
    guardarCotizaciones,
    listGroups,
    registrarServicioPrueba,
    uploadTmpFile,
} from "../../services/conem-services";
import CatalogoServicioPanel from "./CatalogoServicioPanel";
import {Archivo, CatalogoServicio, Grupo, Servicio, Subservicio, WorkingDay} from "../../types/servicio";
import DeleteButton from "../common/DeleteButton";
import PageHeader from "../common/PageHeader";
import ModalMessage from "../common/ModalMessage";
import Loader from "../common/Loader";
import {
    dateToSimpleFormat,
    formatoNumeroFolio,
    getSumaDescuentoSubservicios,
    getSumaPrecioSubservicios,
    getSumaSubtotalSubservicios,
    getSumaTotalServicios,
    isAdmin,
    isCorreoValido,
    PREFIJO_FOLIO,
    SUCCESS,
    toDate,
    truncarTexto
} from "../../utils";
import {useAccordionButton} from "react-bootstrap/AccordionButton";
import CatalogoServicioModal from "../catalogo-servicio/CatalogoServicioModal";
import cloneDeep from 'lodash.clonedeep';
import {omit, toInteger} from "lodash";
import DatePicker from "react-datepicker";
import FileChooser from "../documento/FileChooser";
import DiasSemana from "./DiasSemana";

export default function FormNuevaCotizacion() {

    const hiddenFileInput = useRef(null);

    const [contadorNumeroFolio, setContadorNumeroFolio] = useState<number>(0);
    const [folioAsignado, setFolioAsignado] = useState<number>(-1);
    const [catalogoPrefijoFolio, setCatalogoPrefijoFolio] = useState<string | null>(null);

    const [documentoCotizacion, setDocumentoCotizacion] = useState<Archivo | null>(null);
    const [constanciaFiscal, setConstanciaFiscal] = useState<Archivo | null>(null);
    const [vistoBuenoAniosAnteriores, setVistoBuenoAniosAnteriores] = useState<boolean>(false);
    const [vigenciaVistoBueno, setVigenciaVistoBueno] = useState<Date | null>(null);
    const [representanteLegalInmueble, setRepresentanteLegalInmueble] = useState<string>('');
    const [nombrePersonalRecibiraInspector, setNombrePersonalRecibiraInspector] = useState<string>('');
    const [nivelesConstruccion, setNivelesConstruccion] = useState<string>('');
    const [horarioLaboral, setHorarioLaboral] = useState<string>('');
    const [diasLaborales, setDiasLaborales] = useState<WorkingDay[]>([
        {
            name: 'Domingo', enabled: false
        },
        {
            name: 'Lunes', enabled: true
        },
        {
            name: 'Martes', enabled: true
        },
        {
            name: 'Miércoles', enabled: true
        },
        {
            name: 'Jueves', enabled: true
        },
        {
            name: 'Viernes', enabled: true
        },
        {
            name: 'Sábado', enabled: false
        },
    ]);
    const changeSelectionDay = (index: number, value: boolean) => {
        setDiasLaborales(
            diasLaborales.map((day: WorkingDay, i: number) => {
                if (i === index) {
                    day.enabled = value;
                }
                return day;
            })
        )
    }


    const [errores, setErrores] = useState<any>({});
    const [listaCotizaciones, setListaCotizaciones] = useState<Servicio[]>([]);

    const [loading, setLoading] = useState(false)
    const [catalogoServicioSeleccionado, setCatalogoServicioSeleccionado] = useState<CatalogoServicio | null>(null);
    const [catalogoServicioEditable, setCatalogoServicioEditable] = useState<CatalogoServicio | null>(null);
    const [grupos, setGrupos] = useState<Grupo[] | null>(null);
    const [grupoSeleccionado, setGrupoSeleccionado] = useState<Grupo | null>(null);
    const [listaCatalogoServicio, setListaCatalogoServicio] = useState<CatalogoServicio[]>([]);
    const [catalogoServicioPaginado, setCatalogoServicioPaginado] = useState<CatalogoServicio[]>([]);
    const [indicePaginaCatalogoServicio, setIndicePaginaCatalogoServicio] = useState<number>(-1);
    const [showCatalogoServicioPanel, setShowCatalogoServicioPanel] = useState<boolean>(false);


    const [msgBtnCreateServiceProposal, setMsgBtnCreateServiceProposal] = useState("Enviar cotización");
    const [disableBtnCreateServiceProposal, setDisableBtnCreateServiceProposal] = useState(false);

    const [nombreFolio, setNombreFolio] = useState('')
    const [nombreContacto, setNombreContacto] = useState<string>('');
    const [telefonoContacto, setTelefonoContacto] = useState<string>('');
    const [correoContacto, setCorreoContacto] = useState('');
    const [nombreComercial, setNombreComercial] = useState('');
    const [direccionExacta, setDireccionExacta] = useState('');
    const [giroComercial, setGiroComercial] = useState('');
    const [metrosConstruccion, setMetrosConstruccion] = useState('');
    const [metrosTerreno, setMetrosTerreno] = useState('');
    const [numeroEmpleados, setNumeroEmpleados] = useState('');
    const [sustanciasQuimicosPeligrosos, setSustanciasQuimicosPeligrosos] = useState('');
    const [gasCapacidadAlmacenamiento, setGasCapacidadAlmacenamiento] = useState('');
    const [nivelRiesgo, setNivelRiesgo] = useState('bajo');
    const [tipoCliente, setTipoCliente] = useState('nuevo');
    const [razonSocial, setRazonSocial] = useState('');
    const [rfc, setRfc] = useState('');
    const [domicilioFiscal, setDomicilioFiscal] = useState('');
    const [requiereFactura, setRequiereFactura] = useState<boolean>(false);

    const [total, setTotal] = useState(0);

    const [porcentajeIva, setPorcentajeIva] = useState<number>(16);

    const cargarCatalogoServicio = () => {
        setLoading(true);
        getCatalogoServicios({page: 0, rowsPerPage: 200}).then(data => {
            if (data.numberOfElements > 0) {
                const catalogoServicios = data.items;
                for (let servicio of catalogoServicios) {
                    const sumaDescuentos = servicio?.subservicios.reduce((accumulator: number, subservice: Subservicio) => {
                        return accumulator + (subservice.precio * (subservice.porcentajeDescuento / 100));
                    }, 0);
                    const sumaPrecios = servicio?.subservicios.reduce((accumulator: number, subservice: Subservicio) => {
                        return accumulator + subservice.precio;
                    }, 0);
                    servicio.precio = sumaPrecios;
                    servicio.descuento = sumaDescuentos;
                    servicio.iva = (sumaPrecios - sumaDescuentos) * (porcentajeIva / 100.0);
                    servicio.subtotal = (sumaPrecios - sumaDescuentos) + servicio.iva;
                }
                data.items = catalogoServicios;

                const capacidad = 3;
                setCatalogoServicioPaginado(data.items.filter((item: CatalogoServicio, index: number) => {
                    if (index < capacidad) {
                        return item;
                    }
                }));
                setIndicePaginaCatalogoServicio(data.numberOfElements > capacidad ? 2 : (data.numberOfElements - 1));
                cambiarSeleccionCatalogoServicio(data.items[0]);
                setListaCatalogoServicio(data.items);
            }
        }).catch(error => {
            showError('Ha ocurrido un error al intentar cargar el catálogo de servicios');
        }).finally(() => {
            setLoading(false);
        });

        getConfiguracionSistema().then(data => {
            setPorcentajeIva(data.porcentajeIva);
        }).catch(error => {
            console.log(error)
        });


        listGroups().then(data => {
            setGrupos(data);
            if (data.length > 0) {
                setGrupoSeleccionado(data[0]);
            }
        }).catch(error => {
            console.log(error)
        })

    }

    useEffect(() => {
        cargarCatalogoServicio();
    }, []);

    const limpiarFormulario = () => {
        setFolioAsignado(-1);
        setContadorNumeroFolio(0);
        setNombreFolio('');
        setNombreContacto('');
        setTelefonoContacto('');
        setCorreoContacto('');
        setNombreComercial('');
        setDireccionExacta('');
        setGiroComercial('');
        setMetrosConstruccion('');
        setMetrosTerreno('');
        setNumeroEmpleados('');
        setSustanciasQuimicosPeligrosos('');
        setGasCapacidadAlmacenamiento('');
        setNivelRiesgo('bajo');
        setTipoCliente('nuevo');
        setRazonSocial('');
        setRfc('');
        setDomicilioFiscal('');
        setListaCotizaciones([]);
        showTotal(0);
        setRequiereFactura(false)
        setConstanciaFiscal(null);
        setVigenciaVistoBueno(null);
        setVistoBuenoAniosAnteriores(false);
        setRepresentanteLegalInmueble('');
        setNombrePersonalRecibiraInspector('');
        setNivelesConstruccion('');
        setHorarioLaboral('');
        setDiasLaborales([
            {
                name: 'Domingo', enabled: false
            },
            {
                name: 'Lunes', enabled: true
            },
            {
                name: 'Martes', enabled: true
            },
            {
                name: 'Miércoles', enabled: true
            },
            {
                name: 'Jueves', enabled: true
            },
            {
                name: 'Viernes', enabled: true
            },
            {
                name: 'Sábado', enabled: false
            },
        ]);
    }

    const eliminarCotizacion = (itemIdx: number) => {
        const base = contadorNumeroFolio - listaCotizaciones.length;
        let newCotizacionesList = listaCotizaciones.filter((item: Servicio, index: number) => {
            return (index !== itemIdx);
        })
        showTotal(getSumaTotalServicios(newCotizacionesList));
        setListaCotizaciones(newCotizacionesList.map((i: Servicio, index: number) => {
            i.folio = nombreFolio + '-' + formatoNumeroFolio(base + index);
            return i;
        }));
        setContadorNumeroFolio(contadorNumeroFolio - 1);
    }

    function validarFormulario() {
        let newErrores = {};
        if (!nombreFolio || nombreFolio.trim().length === 0) {
            newErrores = {...newErrores, folio: "Especifique un número de nombreFolio"};
        }
        if (!nombreContacto || nombreContacto.trim().length === 0) {
            newErrores = {...newErrores, nombreContacto: "Especifique un nombre de contacto"};
        }
        if (!telefonoContacto || telefonoContacto.trim().length === 0) {
            newErrores = {...newErrores, telefonoContacto: "Especifique un número de teléfono de contacto"};
        } else if (!telefonoContacto.match(/[0-9]{10}/g)) {
            newErrores = {...newErrores, telefonoContacto: "Especifique un número de teléfono de contacto válido"};
        }
        if (!nombreFolio || nombreFolio.trim().length === 0 || nombreFolio.match(/[A-Z]{3}20[0-9]{2}\\-SPC-[A-Z]+/)) {
            newErrores = {...newErrores, folio: "Especifique un nombreFolio válido"};
        }
        if (!correoContacto || correoContacto.trim().length === 0) {
            newErrores = {...newErrores, correoContacto: "Correo de contacto es requerido"};
        } else if (!isCorreoValido(correoContacto)) {
            newErrores = {...newErrores, correoContacto: "Especifique un correo de contacto válido"};
        }
        if (!nombreComercial || nombreComercial.trim().length === 0) {
            newErrores = {...newErrores, nombreComercial: "Nombre comercial es requerido"};
        }
        if (!direccionExacta || direccionExacta.trim().length === 0) {
            newErrores = {...newErrores, direccionExacta: "Dirección es requerida"};
        }
        if (!giroComercial || giroComercial.trim().length === 0) {
            newErrores = {...newErrores, giroComercial: "Giro comercial es requerido"};
        }
        if (!metrosConstruccion || metrosConstruccion.trim().length === 0) {
            newErrores = {...newErrores, metrosConstruccion: "Metros de construcción es requerido"};
        }
        if (!sustanciasQuimicosPeligrosos || sustanciasQuimicosPeligrosos.trim().length === 0) {
            newErrores = {...newErrores, sustanciasQuimicosPeligrosos: "Indique NA en caso de no aplicar"};
        }
        if (!gasCapacidadAlmacenamiento || gasCapacidadAlmacenamiento.trim().length === 0) {
            newErrores = {...newErrores, gasCapacidadAlmacenamiento: "Indique NA en caso de no aplicar"};
        }
        if (!razonSocial || razonSocial.trim().length === 0) {
            newErrores = {...newErrores, razonSocial: "La razón social es un valor requerido"};
        }
        setErrores(newErrores)
        return Object.keys(newErrores).length !== 0;
    }

    /*agrega la cotizacion actual a la lista temporal antes de enviarla al cliente*/
    const addNewTempCotizacion = async () => {
        if (!validarFormulario() && catalogoServicioSeleccionado) {
            if (documentoCotizacion === null) {
                showError('El documento de cotización es requerido');
            } else {
                const precio = catalogoServicioSeleccionado?.subservicios ? getSumaPrecioSubservicios(catalogoServicioSeleccionado.subservicios) : 0;
                const descuento = catalogoServicioSeleccionado?.subservicios ? getSumaDescuentoSubservicios(catalogoServicioSeleccionado.subservicios) : 0;
                const subtotal = catalogoServicioSeleccionado?.subservicios ? getSumaSubtotalSubservicios(catalogoServicioSeleccionado.subservicios) : 0;
                const iva = subtotal * (porcentajeIva / 100.0);
                // @ts-ignore
                let newService: Servicio = {
                    folioSecretariaEstatal: "",
                    observacionesDocumentacion: null,
                    fechaCapacitacion: null,
                    fechaEstimada: null,
                    fechaInspeccion: null,
                    grupoId: '',
                    observaciones: null,
                    fechaAceptado: null,
                    fechaEnviado: null,
                    fechaExpirado: null,
                    fechaRechazado: null,
                    fechaRegistro: null,
                    tipo: '',
                    status: '',
                    coordinador: null,
                    operador: null,
                    calidad: null,
                    instructor: null,
                    requiereFactura: requiereFactura,
                    grupo: grupoSeleccionado,

                    folio: PREFIJO_FOLIO + '-' + catalogoPrefijoFolio + '-' + nombreFolio + '-' + formatoNumeroFolio(contadorNumeroFolio),
                    constanciaSituacionFiscal: requiereFactura ? constanciaFiscal : null,

                    cliente: {
                        nombreCompleto: nombreContacto,
                        correo: correoContacto,
                        telefono: telefonoContacto,
                        detalle: {
                            nombreComercial: nombreComercial,
                            giro: giroComercial,
                            metrosConstruccion: metrosConstruccion,
                            metrosTerreno: metrosTerreno,
                            numeroEmpleados: numeroEmpleados,
                            direccion: direccionExacta,
                            sustanciasQuimicasPeligrosas: sustanciasQuimicosPeligrosos,
                            gasCapacidadAlmacenamiento: gasCapacidadAlmacenamiento,
                            nivelRiesgo: nivelRiesgo,
                            tipoCliente: tipoCliente,
                            razonSocial: razonSocial,
                            domicilioFiscal: domicilioFiscal,
                            rfc: rfc,
                        }
                    },
                    total: subtotal + iva, ...catalogoServicioSeleccionado
                }
                newService.precio = precio;
                newService.subtotal = subtotal;
                newService.iva = iva;


                newService.plantillaDocumentoCotizacion = catalogoServicioSeleccionado.plantillaDescargable;
                newService.documentoCotizacion = documentoCotizacion;

                newService.representanteLegalInmueble = representanteLegalInmueble;
                newService.nombrePersonalRecibeInspector = nombrePersonalRecibiraInspector;
                newService.horarioLaboral = horarioLaboral;
                newService.diasLaborales = diasLaborales;
                newService.nivelesConstruccion = nivelesConstruccion;
                newService.cuentaVistoBuenoPCAnteriores = vistoBuenoAniosAnteriores;
                if (vigenciaVistoBueno)
                    newService.vigenciaVistoBueno = dateToSimpleFormat(vigenciaVistoBueno);
                else newService.vigenciaVistoBueno = null;

                if (descuento) {
                    newService = {...newService, descuento: descuento};
                }

                const newCotizacionesList = [...listaCotizaciones, newService]
                showTotal(getSumaTotalServicios(newCotizacionesList));
                setListaCotizaciones(newCotizacionesList);

                setDocumentoCotizacion(null);
                setContadorNumeroFolio(contadorNumeroFolio + 1);
            }
        } else {
            showError('Por favor verifique y proporcione los campos requeridos');
        }
    }

    const registrarCotizaciones = async () => {
        if (folioAsignado >= 0 && nombreComercial && catalogoPrefijoFolio) {
            if (listaCotizaciones.length > 0) {
                setMsgBtnCreateServiceProposal("Enviando...");
                setLoading(true);
                setDisableBtnCreateServiceProposal(true);
                guardarCotizaciones({
                    nombreComercial: nombreComercial,
                    folioAsignado: folioAsignado,
                    nombreFolio: nombreFolio,
                    prefijoFolio: catalogoPrefijoFolio,
                    cotizaciones: listaCotizaciones
                }).then((response) => {
                    limpiarFormulario();
                    if (response.codigo === SUCCESS) {
                        showSuccess(response.mensaje);
                    } else {
                        showError(response.mensaje);
                    }
                }).catch((error) => {
                    showError('Lo sentimos ha ocurrido un error al intentar guardar la cotización');
                }).finally(() => {
                    setMsgBtnCreateServiceProposal("Enviar cotización");
                    setLoading(false);
                    setDisableBtnCreateServiceProposal(false);
                })
            }
        }
    }

    const showTotal = (total: number) => {
        setTotal(total)
    }

    const labelCurrency = (value: number | null | undefined) => {
        if (value) {
            return <>${(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</>;
        }
        return <>$0.00</>;
    }

    const [message, setMessage] = useState<string>('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const showSuccess = (message: string) => {
        setError(false);
        setMessage(message);
        setShowMessage(true);
    }

    const showError = (message: string) => {
        setError(true);
        setMessage(message);
        setShowMessage(true);
    }

    const clickSelectFiles = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            // @ts-ignore
            hiddenFileInput.current.click();
        }
    }

    const downloadPreviewDoc = (catalogoServicio: CatalogoServicio) => {

        if (!validarFormulario()) {
            // @ts-ignore
            let servicioPrueba: Servicio = {
                requiereFactura: requiereFactura,
                grupo: grupoSeleccionado,
                plantillaDocumentoCotizacion: catalogoServicio.plantillaDescargable,
                folio: nombreFolio + '-' + formatoNumeroFolio(contadorNumeroFolio),
                cliente: {
                    nombreCompleto: nombreContacto,
                    correo: correoContacto,
                    telefono: telefonoContacto,
                    detalle: {
                        nombreComercial: nombreComercial,
                        giro: giroComercial,
                        metrosConstruccion: metrosConstruccion,
                        metrosTerreno: metrosTerreno,
                        numeroEmpleados: numeroEmpleados,
                        direccion: direccionExacta,
                        sustanciasQuimicasPeligrosas: sustanciasQuimicosPeligrosos,
                        gasCapacidadAlmacenamiento: gasCapacidadAlmacenamiento,
                        nivelRiesgo: nivelRiesgo,
                        tipoCliente: tipoCliente,
                        razonSocial: razonSocial,
                        domicilioFiscal: domicilioFiscal,
                        rfc: rfc,
                    }
                },
                total: catalogoServicio.iva + catalogoServicio.subtotal,
                ...catalogoServicioSeleccionado
            }
            registrarServicioPrueba(servicioPrueba).then(resp => {
                if (resp.codigo === SUCCESS) {
                    fetch(`api/catalogo/cotizacion/prueba/${resp.object.id}`)
                        .then(response => {
                            if (catalogoServicio.plantillaDescargable) {
                                response.blob().then(blob => {
                                    let url = window.URL.createObjectURL(blob);
                                    let a = document.createElement('a');
                                    a.href = url;
                                    a.download = `${catalogoServicio.plantillaDescargable?.nombre}`;
                                    a.click();
                                });
                            }
                        }).catch(err => console.log(err));
                }

            }).catch(err => console.log(err))
        }
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files.item(0);
            if (file && (file.size <= 0 || file.size >= 50 * 1024 * 1024)) {
                showError("El tamaño mínimo de archivo es 1 byte y menor a 50 MB");
                return;
            }

            if (file) {

                setDocumentoCotizacion({
                    dropboxLink: 'undefined',
                    fechaSubido: "",
                    folder: 'undefined',
                    id: 'undefined',
                    seleccionado: false,
                    subidoPor: 'undefined',
                    tamanio: 'undefined',
                    url: "",
                    nombre: file.name
                })


                const formData = new FormData();
                formData.set("file", e.target.files[0]);

                setDocumentoCotizacion(null);
                uploadTmpFile(formData).then(
                    resp => {
                        if (resp.codigo === SUCCESS) {
                            setDocumentoCotizacion(resp.object);
                        } else {
                            showError(resp.mensaje);
                        }
                    }
                ).catch(err => {
                    showError("Lo sentimos ocurrió un error al intentar subir los archivos, por favor inténtelo más tarde");
                })
            }
            // @ts-ignore
            e.target.value = null;
        }
    };

    // @ts-ignore
    function CustomToggle({eventKey}) {
        const [open, setOpen] = useState<boolean>(true);
        const decoratedOnClick = useAccordionButton(eventKey, (e) => {
            setOpen(!open);
        });
        return (
            <span className={open ? 'bi-dash-square' : 'bi-plus-square'} onClick={decoratedOnClick}></span>
        );
    }

    const cambiarSeleccionCatalogoServicio = (e: CatalogoServicio) => {
        setCatalogoServicioSeleccionado(e);
        setCatalogoPrefijoFolio(e.prefijoFolio);
        //actualizar el numero de folio
        if (nombreComercial) {
            generarFolioCliente(nombreComercial, e.prefijoFolio).then(resp => {
                if (resp.codigo === SUCCESS) {
                    const numeroFolio = toInteger(resp.object.contador);
                    setFolioAsignado(numeroFolio);
                    setContadorNumeroFolio(numeroFolio + 1);
                    let folio = truncarTexto(nombreComercial.toUpperCase().replace(/[\s|\.|,|_|-]/g, ''), 20, false);
                    if (resp.object.prefijoFolio) {
                        folio = resp.object.prefijoFolio;
                    }
                    setNombreFolio(folio);
                } else {
                    showError(resp.mensaje);
                }
            }).catch(err => showError("Error ocurrido al intentar generar el nombreFolio"));
        }
    }

    return (
        <>
            <Loader show={loading}></Loader>
            <Container style={{background: '#FAFAFA'}}>
                <PageHeader label="Nueva cotización"/>
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Header className="list-header">
                            <CustomToggle eventKey="0"></CustomToggle>
                            <label className='fs-5 fw-bold' style={{marginLeft: "10px"}}>
                                Información general de la cotización
                            </label>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Container>
                                    <Form className="row g-6" id="formGeneral">
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Correo del Contacto</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control disabled={listaCotizaciones.length > 0}
                                                          type="email" className="formFont" maxLength={100} required
                                                          value={correoContacto} isInvalid={!!errores.correoContacto}
                                                          onChange={(e) => setCorreoContacto(e.target.value)}
                                                          onBlur={async (e) => {
                                                              if (!correoContacto || correoContacto.trim().length === 0) {
                                                                  setErrores({
                                                                      errores,
                                                                      correoContacto: "Correo de contacto es requerido"
                                                                  });
                                                              } else if (!isCorreoValido(correoContacto)) {
                                                                  setErrores({
                                                                      errores,
                                                                      correoContacto: "Especifique un correo de contacto válido"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'correoContacto'));
                                                                  if (catalogoPrefijoFolio) {
                                                                      try {
                                                                          const response = await buscarClienteByCorreo(correoContacto, catalogoPrefijoFolio);
                                                                          if (response.codigo === SUCCESS) {
                                                                              const cliente = response.object;
                                                                              setNombreContacto(cliente.nombreCompleto);
                                                                              setTelefonoContacto(cliente.telefono);
                                                                              setNombreComercial(cliente.detalle.nombreComercial);
                                                                              setGiroComercial(cliente.detalle.giro);
                                                                              setDireccionExacta(cliente.detalle.direccion);
                                                                              setMetrosTerreno(cliente.detalle.metrosTerreno);
                                                                              setMetrosConstruccion(cliente.detalle.metrosConstruccion);
                                                                              setNumeroEmpleados(cliente.detalle.numeroEmpleados);
                                                                              setSustanciasQuimicosPeligrosos(cliente.detalle.sustanciasQuimicasPeligrosas);
                                                                              setGasCapacidadAlmacenamiento(cliente.detalle.gasCapacidadAlmacenamiento);
                                                                              setRazonSocial(cliente.detalle.razonSocial);
                                                                              setRfc(cliente.detalle.rfc);
                                                                              setDomicilioFiscal(cliente.detalle.domicilioFiscal);
                                                                              setNivelRiesgo(cliente.detalle.nivelRiesgo);
                                                                              setTipoCliente('renovacion');
                                                                              setRequiereFactura(cliente.requiereFactura);
                                                                              setRepresentanteLegalInmueble(cliente.representanteLegalInmueble);
                                                                              setNombrePersonalRecibiraInspector(cliente.nombrePersonalRecibeInspector);
                                                                              setHorarioLaboral(cliente.horarioLaboral);
                                                                              if (cliente.diasLaborales)
                                                                                  setDiasLaborales(cliente.diasLaborales);
                                                                              setNivelesConstruccion(cliente.nivelesConstruccion);
                                                                              setVistoBuenoAniosAnteriores(cliente.cuentaVistoBuenoPCAnteriores);
                                                                              if (cliente.vigenciaVistoBueno)
                                                                                  setVigenciaVistoBueno(toDate(cliente.vigenciaVistoBueno));
                                                                              if (constanciaFiscal == null && cliente.constanciaSituacionFiscal) {
                                                                                  setRequiereFactura(true);
                                                                                  setConstanciaFiscal(cliente.constanciaSituacionFiscal);
                                                                              }
                                                                              if (cliente.nombreFolio) {
                                                                                  setNombreFolio(cliente.nombreFolio)
                                                                              }
                                                                              if (cliente.contadorFolio) {
                                                                                  const numeroFolio = toInteger(cliente.contadorFolio);
                                                                                  setFolioAsignado(numeroFolio);
                                                                                  setContadorNumeroFolio(numeroFolio + 1);
                                                                              }
                                                                              if (isAdmin()) {
                                                                                  setGrupoSeleccionado(cliente.grupo);
                                                                              }
                                                                          }
                                                                      } catch (err) {
                                                                      }
                                                                  }
                                                              }

                                                          }}
                                            ></Form.Control>
                                            <Form.Control.Feedback
                                                type="invalid">{errores.correoContacto}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Nombre del Contacto</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control disabled={listaCotizaciones.length > 0}
                                                          type="text" className="formFont" max={200} required
                                                          value={nombreContacto} isInvalid={!!errores.nombreContacto}
                                                          onChange={(e) => setNombreContacto(e.target.value)}
                                                          onBlur={() => {
                                                              if (!nombreContacto || nombreContacto.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      nombreContacto: "Especifique un nombre de contacto"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'nombreContacto'))
                                                              }
                                                          }}/>
                                            <Form.Control.Feedback
                                                type="invalid">{errores.nombreContacto}</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Teléfono del Contacto</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control disabled={listaCotizaciones.length > 0}
                                                          type="text" className="formFont" maxLength={10} required
                                                          value={telefonoContacto}
                                                          isInvalid={!!errores.telefonoContacto}
                                                          onChange={(e) => setTelefonoContacto(e.target.value)}
                                                          onBlur={() => {
                                                              if (!telefonoContacto || telefonoContacto.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      telefonoContacto: "Especifique un número de teléfono de contacto"
                                                                  });
                                                              } else if (!telefonoContacto.match(/[0-9]{10}/g)) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      telefonoContacto: "Especifique un número de teléfono de contacto válido"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'telefonoContacto'))
                                                              }
                                                          }}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errores.telefonoContacto}</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Nombre Comercial</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control type="text" className="formFont"
                                                          disabled={listaCotizaciones.length > 0} maxLength={200}
                                                          value={nombreComercial}
                                                          isInvalid={!!errores.nombreComercial}
                                                          onChange={(e) => {
                                                              setNombreComercial(e.target.value)
                                                          }}
                                                          onBlur={() => {
                                                              if (!nombreComercial || nombreComercial.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      nombreComercial: "Nombre comercial es requerido"
                                                                  });
                                                              } else {
                                                                  if (catalogoPrefijoFolio) {
                                                                      generarFolioCliente(nombreComercial, catalogoPrefijoFolio).then(resp => {
                                                                          if (resp.codigo === SUCCESS) {
                                                                              const numeroFolio = toInteger(resp.object.contador);
                                                                              setFolioAsignado(numeroFolio);
                                                                              setContadorNumeroFolio(numeroFolio + 1);
                                                                              let folio = truncarTexto(nombreComercial.toUpperCase().replace(/[\s|\.|,|_|-]/g, ''), 20, false);
                                                                              if (resp.object.prefijoFolio) {
                                                                                  folio = resp.object.prefijoFolio;
                                                                              }
                                                                              setNombreFolio(folio);
                                                                          } else {
                                                                              showError(resp.mensaje);
                                                                          }
                                                                      }).catch(err => showError("Error ocurrido al intentar generar el nombreFolio"));
                                                                  }
                                                                  setErrores(omit(errores, 'nombreComercial'))
                                                              }
                                                          }}
                                            ></Form.Control>
                                            <Form.Control.Feedback
                                                type="invalid">{errores.nombreComercial}</Form.Control.Feedback>

                                        </Form.Group>

                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Folio</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <div>
                                                <Row>
                                                    <Col sm='4'>
                                                        <Form.Control type='text' disabled={true}
                                                                      value={`${PREFIJO_FOLIO}-${catalogoPrefijoFolio ? catalogoPrefijoFolio : 'XXX'}`}/>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="text"
                                                                      disabled={listaCotizaciones.length > 0}
                                                                      className="formFont" max={200}
                                                                      required value={nombreFolio}
                                                                      isInvalid={!!errores.folio}
                                                                      onChange={(e) => {
                                                                          setNombreFolio(e.target.value)
                                                                      }}
                                                                      onBlur={() => {
                                                                          if (!nombreFolio || nombreFolio.trim().length === 0 || !nombreFolio.match(/[A-Z]+/)) {
                                                                              setErrores({
                                                                                  ...errores,
                                                                                  folio: "Especifique un nombreFolio válido"
                                                                              });
                                                                          } else {
                                                                              setErrores(omit(errores, 'folio'))
                                                                          }
                                                                      }}

                                                        />
                                                    </Col>
                                                    <Col sm='4'>
                                                        <Form.Control type='text' disabled={true}
                                                                      value={formatoNumeroFolio(contadorNumeroFolio)}/>
                                                        {
                                                            contadorNumeroFolio > 0 && <span className='text-muted'
                                                                                             style={{fontSize: '0.5rem'}}><span
                                                                className='bi bi-info-circle'/> Folio asignado automáticamente</span>
                                                        }
                                                    </Col>
                                                </Row>


                                            </div>

                                            <Form.Control.Feedback
                                                type="invalid">{errores.folio}</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Dirección Exacta del Inmueble</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control type="text" className="formFont" maxLength={400}
                                                          value={direccionExacta}
                                                          onChange={(e) => {
                                                              setDireccionExacta(e.target.value)
                                                          }}
                                                          isInvalid={!!errores.direccionExacta}
                                                          onBlur={() => {
                                                              if (!direccionExacta || direccionExacta.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      direccionExacta: "La dirección exacta es requerida"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'direccionExacta'));
                                                              }
                                                          }}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errores.direccionExacta}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Giro Comercial</Form.Label>
                                            <Form.Control type="text" className="formFont" maxLength={400}
                                                          value={giroComercial} isInvalid={!!errores.giroComercial}
                                                          onChange={(e) => setGiroComercial(e.target.value)}
                                                          onBlur={() => {
                                                              if (!giroComercial || giroComercial.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      giroComercial: "El campo Giro Comercial es requerido"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'giroComercial'));
                                                              }
                                                          }}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errores.giroComercial}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Metros cuadrados de Construcción</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control type="text" className="formFont" maxLength={100}
                                                          isInvalid={!!errores.metrosConstruccion}
                                                          value={metrosConstruccion}
                                                          onChange={(e) => setMetrosConstruccion(e.target.value)}
                                                          onBlur={() => {
                                                              if (!metrosConstruccion || metrosConstruccion.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      metrosConstruccion: "El campo metros de construcción es requerido"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'metrosConstruccion'));
                                                              }
                                                          }}

                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errores.metrosConstruccion}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Metros cuadrados de Terreno</Form.Label>
                                            <Form.Control type="text" className="formFont" maxLength={100}
                                                          isInvalid={!!errores.metrosTerreno}
                                                          value={metrosTerreno}
                                                          onChange={(e) => setMetrosTerreno(e.target.value)}></Form.Control>
                                            <Form.Control.Feedback
                                                type="invalid">{errores.metrosTerreno}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>No. de empleados</Form.Label>
                                            <Form.Control type="text" className="formFont" maxLength={100}
                                                          isInvalid={!!errores.numeroEmpleados}
                                                          value={numeroEmpleados}
                                                          onChange={(e) => setNumeroEmpleados(e.target.value)}></Form.Control>
                                            <Form.Control.Feedback
                                                type="invalid">{errores.numeroEmpleados}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Sustancias o Químicos Peligrosos</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control type="text" className="formFont" maxLength={100}
                                                          isInvalid={!!errores.sustanciasQuimicosPeligrosos}
                                                          value={sustanciasQuimicosPeligrosos}
                                                          onChange={(e) => setSustanciasQuimicosPeligrosos(e.target.value)}
                                                          onBlur={() => {
                                                              if (!sustanciasQuimicosPeligrosos || sustanciasQuimicosPeligrosos.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      sustanciasQuimicosPeligrosos: "Indique NA en caso de no aplicar"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'sustanciasQuimicosPeligrosos'))
                                                              }
                                                          }}

                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errores.sustanciasQuimicosPeligrosos}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Gas, capacidad de almacenamiento</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control type="text" className="formFont" maxLength={100}
                                                          isInvalid={!!errores.gasCapacidadAlmacenamiento}
                                                          value={gasCapacidadAlmacenamiento}
                                                          onChange={(e) => setGasCapacidadAlmacenamiento(e.target.value)}
                                                          onBlur={() => {
                                                              if (!gasCapacidadAlmacenamiento || gasCapacidadAlmacenamiento.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      gasCapacidadAlmacenamiento: "Indique NA en caso de no aplicar"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'gasCapacidadAlmacenamiento'))
                                                              }
                                                          }}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errores.gasCapacidadAlmacenamiento}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Razón Social</Form.Label>
                                            <Form.Text className="text-muted"> (Se requiere el nombre de la empresa ante
                                                el
                                                SAT)</Form.Text>
                                            <Form.Control type="text" className="formFont" maxLength={100}
                                                          isInvalid={!!errores.razonSocial}
                                                          value={razonSocial}
                                                          onChange={(e) => setRazonSocial(e.target.value)}
                                                          onBlur={() => {
                                                              if (!razonSocial || razonSocial.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      razonSocial: "La razón social es un valor requerido"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'razonSocial'))
                                                              }
                                                          }}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errores.razonSocial}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Domicilio Fiscal</Form.Label>
                                            <Form.Control type="text" className="formFont" maxLength={400}
                                                          value={domicilioFiscal}
                                                          onChange={(e) => setDomicilioFiscal(e.target.value)}></Form.Control>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>RFC (Registro Federal de Contribuyente)</Form.Label>
                                            <Form.Control type="text" className="formFont" maxLength={30}
                                                          value={rfc}
                                                          onChange={(e) => setRfc(e.target.value)}></Form.Control>
                                        </Form.Group>
                                        <Form.Group></Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral">
                                            <Form.Label>Nivel de riesgo</Form.Label>
                                            <div className="mb-3">
                                                <Form.Check
                                                    checked={nivelRiesgo === 'bajo'}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setNivelRiesgo('bajo')
                                                        }
                                                    }} inline label="Bajo" type='radio'
                                                />
                                                <Form.Check
                                                    checked={nivelRiesgo === 'medio'}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setNivelRiesgo('medio')
                                                        }
                                                    }} inline label="Medio" type='radio'
                                                />
                                                <Form.Check
                                                    checked={nivelRiesgo === 'alto'}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setNivelRiesgo('alto')
                                                        }
                                                    }} inline label="Alto" type='radio'
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral">
                                            <Form.Label>Tipo de cliente</Form.Label>
                                            <div className="mb-3">
                                                <Form.Check
                                                    checked={tipoCliente === 'nuevo'}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setTipoCliente('nuevo')
                                                        }
                                                    }} inline label="Nuevo" type='radio'
                                                />
                                                <Form.Check
                                                    checked={tipoCliente === 'renovacion'}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setTipoCliente('renovacion')
                                                        }
                                                    }} inline label="Renovación" type='radio'
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form className="row g-6" id="formDatosGeneralesOperativos">
                                            <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                                        style={{marginBottom: "20px"}}>
                                                <Form.Label>Representante Legal del Inmueble</Form.Label>
                                                <Form.Control type="text" className="formFont"
                                                              value={representanteLegalInmueble}
                                                              onChange={(e) => setRepresentanteLegalInmueble(e.target.value)}
                                                              maxLength={100}></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                                        style={{marginBottom: "20px"}}>
                                                <Form.Label>Nombre del personal que recibirá al inspector</Form.Label>
                                                <Form.Control type="text" className="formFont"
                                                              value={nombrePersonalRecibiraInspector}
                                                              onChange={(e) => setNombrePersonalRecibiraInspector(e.target.value)}
                                                              maxLength={100}></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                                        style={{marginBottom: "20px"}}>
                                                <Form.Label>Niveles de construcción</Form.Label>
                                                <Form.Control type="text" className="formFont"
                                                              value={nivelesConstruccion}
                                                              onChange={(e) => setNivelesConstruccion(e.target.value)}
                                                              maxLength={50}></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                                        style={{marginBottom: "20px"}}>
                                                <Form.Label>Horario laboral</Form.Label>
                                                <Form.Control type="text" className="formFont"
                                                              value={horarioLaboral}
                                                              onChange={(e) => setHorarioLaboral(e.target.value)}
                                                              maxLength={50}></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                                        style={{marginBottom: "20px"}}>
                                                <Form.Label>Días laborales</Form.Label>
                                                <div>
                                                    <DiasSemana workingDays={diasLaborales} disabled={false}
                                                                change={changeSelectionDay}/>
                                                </div>
                                            </Form.Group>

                                            <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                                        style={{marginBottom: "20px"}}>
                                                <Form.Label>¿Cuentan con visto bueno de protección civil de años
                                                    anteriores?</Form.Label>
                                                <div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox"
                                                               id='requiereFactura'
                                                               checked={vistoBuenoAniosAnteriores}
                                                               onChange={(e) => setVistoBuenoAniosAnteriores(e.target.checked)}/>
                                                        <label className="form-check-label" htmlFor="requiereFactura">
                                                            Sí
                                                        </label>
                                                    </div>
                                                    {
                                                        vistoBuenoAniosAnteriores &&
                                                        <div>
                                                            Vigencia:
                                                            <DatePicker isClearable locale="es"
                                                                        dateFormat='dd/MM/yyyy'
                                                                        selected={vigenciaVistoBueno}
                                                                        onChange={(date, event) => {
                                                                            setVigenciaVistoBueno(date);
                                                                        }}/>
                                                        </div>
                                                    }
                                                </div>

                                            </Form.Group>
                                        </Form>
                                    </Form>
                                    <div style={{marginTop: "10px"}}>
                                        <div className="form-check">
                                            <label className="form-check-label" htmlFor="requiereFactura">
                                                ¿Requiere factura?
                                            </label>
                                            <input className="form-check-input" type="checkbox"
                                                   id='requiereFactura'
                                                   checked={requiereFactura}
                                                   onChange={(e) => setRequiereFactura(e.target.checked)}/>
                                            {
                                                requiereFactura &&
                                                <FileChooser archivo={constanciaFiscal} disabled={false}
                                                             buttonUploadLabel={`${constanciaFiscal === null ? 'Agregar' : 'Actualizar'} constancia de situación fiscal`}
                                                             updatedFile={(e: Archivo) => setConstanciaFiscal(e)}/>
                                            }
                                        </div>
                                    </div>
                                    <hr/>
                                    {
                                        isAdmin() &&
                                        <><Form.Label>Grupo de atención al que pertenece esta cotización</Form.Label>
                                            <div className="mb-3">
                                                {
                                                    grupos?.map((g: Grupo) => {
                                                        return <Form.Check
                                                            checked={grupoSeleccionado?.id === g.id}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setGrupoSeleccionado(g);
                                                                }
                                                            }} inline label={g.nombre} type='radio'
                                                        />
                                                    })
                                                }
                                            </div>
                                        </>

                                    }


                                    <Container className="d-flex flex-row justify-content-center"
                                               style={{marginTop: "30px", marginBottom: '30px'}}>

                                        <input id="file" type="file" ref={hiddenFileInput} accept='application/pdf'
                                               style={{display: 'none'}}
                                               onChange={handleFileChange}/>

                                        {
                                            listaCatalogoServicio === null && <>
                                                <span className="fs-4 fw-bold">Lo sentimos ha ocurrido un error al cargar el catálogo de servicios...</span>
                                            </>
                                        }
                                        {
                                            indicePaginaCatalogoServicio > 2 &&
                                            <Button variant='' style={{fontSize: '1.5rem'}} onClick={() => {
                                                const i = indicePaginaCatalogoServicio - 1;
                                                setIndicePaginaCatalogoServicio(i);
                                                const copia = listaCatalogoServicio.filter((item: CatalogoServicio, index: number) => {
                                                    if (index >= (i - 2) && index <= i) return item;
                                                })
                                                setCatalogoServicioPaginado(copia);
                                            }}><i className="bi bi-chevron-double-left"></i>
                                            </Button>
                                        }
                                        {
                                            catalogoServicioPaginado.map((catalogoServicio: CatalogoServicio) => {
                                                return (
                                                    <CatalogoServicioPanel catalogoServicio={catalogoServicio}
                                                                           seleccionado={(catalogoServicio.id === catalogoServicioSeleccionado?.id)}
                                                                           seleccionar={(e: CatalogoServicio) => {
                                                                               setDocumentoCotizacion(null);
                                                                               cambiarSeleccionCatalogoServicio(e);
                                                                           }}
                                                                           editar={(e: CatalogoServicio) => {
                                                                               const copy = cloneDeep(e);
                                                                               setCatalogoServicioEditable(copy);
                                                                               setShowCatalogoServicioPanel(true);
                                                                           }}
                                                                           downloadPreview={() => downloadPreviewDoc(catalogoServicio)}

                                                    />
                                                )
                                            })
                                        }
                                        {
                                            indicePaginaCatalogoServicio < (listaCatalogoServicio.length - 1) &&
                                            <Button variant='' style={{fontSize: '1.5rem'}} onClick={() => {
                                                const i = indicePaginaCatalogoServicio + 1;
                                                setIndicePaginaCatalogoServicio(i);
                                                const copia = listaCatalogoServicio.filter((item: CatalogoServicio, index: number) => {
                                                    if (index >= (i - 2) && index <= i) return item;

                                                })
                                                setCatalogoServicioPaginado(copia);
                                            }}>
                                                <i className="bi bi-chevron-double-right"></i>
                                            </Button>
                                        }
                                    </Container>

                                    {
                                        documentoCotizacion === null &&
                                        <Container className="shadow-sm text-xl-center dashed-box d-flex justify-content-center align-items-center" style={{ marginTop: '10px'}}>
                                            <div>
                                                <label className='fs-4 fw-bold folderName' style={{color: 'gray'}}  onClick={clickSelectFiles}>Adjuntar documento de cotización</label>
                                            </div>
                                        </Container>
                                    }
                                    {
                                        documentoCotizacion &&
                                        <div className='rounded shadow-lg d-flex align-items-center d-inline-flex'
                                             style={{
                                                 marginTop: '10px',
                                                 width: '100%',
                                                 border: '2px solid gray',
                                                 height: '80px',
                                                 backgroundColor: '#fafafa'
                                             }}>
                                            <div style={{marginLeft: '30px', width: '93%'}}>
                                                <span style={{color: 'gray'}} className='fs-3 bi bi-file-earmark'/>
                                                <span className='folderName fw-bold fs-5' onClick={() => {
                                                    if (documentoCotizacion?.url) {
                                                        fetch(documentoCotizacion.url)
                                                            .then(response => {
                                                                response.blob().then(blob => {
                                                                    let url = window.URL.createObjectURL(blob);
                                                                    let a = document.createElement('a');
                                                                    a.href = url;
                                                                    a.download = `${documentoCotizacion.nombre}`;
                                                                    a.click();
                                                                });
                                                            });
                                                    }
                                                }}
                                                      style={{color: 'gray'}}>{documentoCotizacion.nombre}</span>
                                            </div>
                                            <div className='d-flex justify-content-end'>
                                                <DeleteButton onClick={() => {
                                                    setDocumentoCotizacion(null);
                                                }}/>
                                            </div>
                                        </div>
                                    }


                                </Container>
                                {
                                    listaCatalogoServicio.length > 0 &&
                                    <Container className="d-flex flex-row justify-content-center"
                                               style={{marginTop: "30px"}}>
                                        <Button style={{padding: "15px"}}
                                                variant="dark" size='sm'
                                                onClick={addNewTempCotizacion}>
                                            Añadir a la lista de cotizaciones
                                        </Button>
                                    </Container>
                                }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
                <Container style={{
                    marginTop: "20px", paddingTop: "20px", paddingBottom: "20px",
                    backgroundColor: 'rgba(254,254,254,0.98)', color: "black", border: "1px solid lightgray",
                    borderRadius: "8px"
                }}>

                    <Table bordered hover className="table">
                        <thead>
                        <tr>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>Folio</th>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>Nombre comercial</th>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>Precio</th>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>Descuento</th>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>Subtotal</th>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>I.V.A</th>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>Total</th>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>Opciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            listaCotizaciones.map((serviceProposal: Servicio, index: any) => {
                                return (
                                    <tr>
                                        <td><span className='fw-bold'>{truncarTexto(serviceProposal.folio, 40)}</span>
                                        </td>
                                        <td><span className='fw-bold'>{truncarTexto(serviceProposal.cliente.detalle.nombreComercial, 30)}</span>
                                        </td>
                                        <td><span className='fw-bold'>{labelCurrency(serviceProposal.precio)}</span>
                                        </td>
                                        <td><span
                                            className='fw-bold'>{serviceProposal.descuento ? labelCurrency(serviceProposal.descuento) : 'N/A'}</span>
                                        </td>
                                        <td><span className='fw-bold'>{labelCurrency(serviceProposal.subtotal)}</span>
                                        </td>
                                        <td><span className='fw-bold'>{labelCurrency(serviceProposal.iva)}</span></td>
                                        <td><span className='fw-bold'>{labelCurrency(serviceProposal.total)}</span></td>
                                        <td>
                                            <div className='d-flex justify-content-center'>
                                                <DeleteButton onClick={() => {
                                                    eliminarCotizacion(index);
                                                }}/>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>

                    <Row>
                        <Col className="col-sm-7">
                        </Col>
                        <Col>
                            <TotalLabel total={total} withIva={true}/>
                        </Col>
                    </Row>
                    {
                        listaCotizaciones.length > 0 &&
                        <Container className="d-flex flex-row justify-content-center">
                            <Button style={{padding: "10px", width: "200px"}} variant="dark" size='sm'
                                    disabled={disableBtnCreateServiceProposal}
                                    onClick={registrarCotizaciones}>
                                {msgBtnCreateServiceProposal}
                            </Button>
                        </Container>
                    }
                </Container>
                <br/>
            </Container>

            {
                catalogoServicioEditable &&
                <CatalogoServicioModal show={showCatalogoServicioPanel}
                                       downloadDocCotizacionPrueba={(tempCatalogoServicio: CatalogoServicio) => {
                                           if (!validarFormulario() && tempCatalogoServicio) {

                                               // @ts-ignore
                                               let servicioPrueba: Servicio = {
                                                   requiereFactura: requiereFactura,
                                                   grupo: grupoSeleccionado,
                                                   plantillaDocumentoCotizacion: tempCatalogoServicio.plantillaDescargable,
                                                   folio: nombreFolio,
                                                   cliente: {
                                                       nombreCompleto: nombreContacto,
                                                       correo: correoContacto,
                                                       telefono: telefonoContacto,
                                                       detalle: {
                                                           nombreComercial: nombreComercial,
                                                           giro: giroComercial,
                                                           metrosConstruccion: metrosConstruccion,
                                                           metrosTerreno: metrosTerreno,
                                                           numeroEmpleados: numeroEmpleados,
                                                           direccion: direccionExacta,
                                                           sustanciasQuimicasPeligrosas: sustanciasQuimicosPeligrosos,
                                                           gasCapacidadAlmacenamiento: gasCapacidadAlmacenamiento,
                                                           nivelRiesgo: nivelRiesgo,
                                                           tipoCliente: tipoCliente,
                                                           razonSocial: razonSocial,
                                                           domicilioFiscal: domicilioFiscal,
                                                           rfc: rfc,
                                                       }
                                                   },
                                                   total: tempCatalogoServicio.iva + tempCatalogoServicio.subtotal,
                                                   ...catalogoServicioSeleccionado
                                               }
                                               registrarServicioPrueba(servicioPrueba).then(resp => {
                                                   if (resp.codigo === SUCCESS) {
                                                       fetch(`api/catalogo/cotizacion/prueba/${resp.object.id}`)
                                                           .then(response => {
                                                               if (tempCatalogoServicio.plantillaDescargable) {
                                                                   response.blob().then(blob => {
                                                                       let url = window.URL.createObjectURL(blob);
                                                                       let a = document.createElement('a');
                                                                       a.href = url;
                                                                       a.download = `${tempCatalogoServicio.plantillaDescargable?.nombre}`;
                                                                       a.click();
                                                                   });
                                                               }
                                                           });
                                                   }

                                               }).catch(err => alert(err))

                                           }
                                       }}
                                       cambiarPlantilla={uploadTmpFile}
                                       catalogoServicio={catalogoServicioEditable}
                                       close={() => {
                                           setShowCatalogoServicioPanel(false)
                                       }}
                                       guardarCambios={(e: CatalogoServicio) => {
                                           const newCatalogoServicioPaginado = catalogoServicioPaginado.map((item: CatalogoServicio) => {
                                               if (item.id === e.id) return e;
                                               else return item;
                                           });
                                           setCatalogoServicioPaginado(newCatalogoServicioPaginado);
                                           cambiarSeleccionCatalogoServicio(e);
                                           setShowCatalogoServicioPanel(false);
                                       }}/>

            }


            <ModalMessage show={showMessage} close={() => setShowMessage(false)} error={error} message={message}/>

        </>
    )
}