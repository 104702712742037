import {Col, Container, ListGroup, Row} from "react-bootstrap";
import React from "react";

export const DashboardAdministrativo=()=>{
    return (
        <Container style={{marginTop: '20px',marginBottom: '20px'}}>
            <ListGroup>
                <ListGroup.Item style={{backgroundColor: '#dadada', color: 'black'}}
                                key={'header'}>
                    <Row>
                        <Col>Cobranza</Col>
                        <Col>Enero-Febrero</Col>
                        <Col>Marzo-Abril</Col>
                        <Col>Mayo-Junio</Col>
                        <Col>Julio-Agosto</Col>
                        <Col>Septiembre-Octubre</Col>
                        <Col>Noviembre-Diciembre</Col>
                        </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col># créditos autorizados</Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col># de anticipos pendientes</Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col># de liquidaciones pendientes</Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                </ListGroup.Item>
            </ListGroup>
        </Container>
    )
};