import React, {useEffect, useRef, useState} from "react";
import {Alert, Button, Col, Row} from "react-bootstrap";
import {Correo, Destinatario, Evento} from "../../types/servicio";
import {EditText} from "react-edit-text";
import {actualizarEvento, sendTestEmail, uploadTmpFile} from "../../services/conem-services";
import {getUsuarioSesionUsername, isCorreoValido, SUCCESS} from "../../utils";


export default function EventoItem(
    props: {
        evento: Evento,
        onUpdate: (e: Evento) => void
    }
) {

    const [correo, setCorreo] = useState<Correo | null>(null);
    const [correoPrueba, setCorreoPrueba] = useState<string>(getUsuarioSesionUsername());
    const [uploading, setUploading] = useState<boolean>(false);
    const hiddenFileInput = useRef(null);

    useEffect(() => {
        if (props.evento.correo) {
            setCorreo(props.evento.correo)
        } else {
            setCorreo(null);
        }

        setCorreoPrueba(getUsuarioSesionUsername());
    }, [props.evento]);


    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const showError = (message: string) => {
        setErrorMessage(message);
        setError(true);
    }

    const [notification,setNotification] = useState<boolean>(false);
    const [notificationMessage,setNotificationMessage] = useState<string>('');
    const showNotification=(message:string)=>{
        setNotificationMessage(message);
        setNotification(true);
    }

    const chooseButtonClick = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            // @ts-ignore
            hiddenFileInput.current.click();
        }
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files.item(0);
            if (file && (file.size <= 0 || file.size >= 50 * 1024 * 1024)) {
                showError("El tamaño mínimo de archivo es 1 byte y menor a 50 MB");
                return;
            }

            if (file && correo) {

                const formData = new FormData();
                formData.set("file", e.target.files[0]);

                uploadTmpFile(formData).then(
                    resp => {
                        if (resp.codigo === SUCCESS) {
                            const newCorreo = {
                                ...correo,
                                plantilla: resp.object
                            };
                            setCorreo(newCorreo);
                        } else {
                            showError(resp.mensaje);
                        }
                    }
                ).catch(err => {
                    showError("Lo sentimos ocurrió un error al intentar subir los archivos, por favor inténtelo más tarde");
                })
            }
            // @ts-ignore
            e.target.value = null;

        }
    };

    const enviarCorreoPrueba = () => {
        if (isCorreoValido(correoPrueba) && correo) {
            sendTestEmail(props.evento.id, {
                id: props.evento.id,
                claveEvento: props.evento.claveEvento,
                correo: correo,
                destinatario: correoPrueba
            }).then(resp => {
                if (resp.codigo===SUCCESS){
                    showNotification("El correo de prueba ha sido enviado")
                }else{
                    showError(resp.mensaje);
                }
            }).catch(err => {
                showError("Lo sentimos ocurrió un error al intentar enviar el correo de prueba, por favor inténtelo mas tarde");
            })

        } else {
            showError("Proporcione un correo válido");
        }
    }

    const OpcionDestinatario = (destinatario: Destinatario, onChange: (checked: boolean) => void) => {
        return (
            <label className="badge" style={{
                color: 'black',
                backgroundColor: '#e8e8e8',
                marginLeft: '5px'
            }}>
                <span><input type="checkbox" disabled={!destinatario.editable}
                             checked={destinatario.habilitado}
                             onChange={(e) => {
                                 onChange(e.target.checked);
                             }}/> {destinatario.nombre}</span>
            </label>
        )
    }

    return (
        <>
            {
                correo && <>
                    <Row>
                        <input id="file" type="file" ref={hiddenFileInput} accept=".html"
                               style={{display: 'none'}}
                               onChange={handleFileChange}/>
                        <Col>
                            El correo se enviará a:
                            <div className='d-inline-flex'>
                                {
                                    correo.destinatarios.map((destinatario: Destinatario) => {
                                        return OpcionDestinatario(destinatario, (checked: boolean) => {
                                            if (destinatario.editable) {
                                                destinatario.habilitado = checked;
                                                const newDestinatarios = correo.destinatarios.map((des: Destinatario) => {
                                                    if (des.clave === destinatario.clave) return destinatario;
                                                    return des;
                                                });
                                                const newCorreo = {
                                                    ...correo,
                                                    destinatarios: newDestinatarios
                                                };
                                                setCorreo(newCorreo);
                                            }
                                        });
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='d-inline-flex'>
                                <span style={{marginTop: '7px'}}>El asunto de correo será:</span>
                                <div style={{width: '70vh'}}>
                                    <EditText value={correo.subject}
                                              onChange={(e) => {
                                                  const newCorreo = {
                                                      ...correo,
                                                      subject: e.target.value
                                                  };
                                                  setCorreo(newCorreo)
                                              }}
                                    />
                                    {
                                        !correo.subject &&
                                        <label className="file-size-label" style={{color: 'red'}}>
                                            El título del correo es requerido
                                        </label>
                                    }

                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='d-inline-flex'>
                                <label style={{marginRight: '10px', marginTop: '7px'}}>Plantilla de correo utilizada:</label>
                                <label className='fw-bold folderName' onClick={() => {
                                    fetch(`/api/eventos/correo/plantilla/${props.evento.id}`)
                                        .then(response => {
                                            response.blob().then(blob => {
                                                let url = window.URL.createObjectURL(blob);
                                                let a = document.createElement('a');
                                                a.href = url;
                                                a.download = `${correo.plantilla.nombre}`;
                                                a.click();
                                            });
                                        });
                                }}> <span
                                    className='fs-4 bi bi-filetype-html'/>{correo?.plantilla.nombre}</label>
                                <div style={{marginLeft: '10px', marginTop: '7px'}}>
                                    <a href='#' onClick={chooseButtonClick}>Cambiar la plantilla de correo</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='d-inline-flex'>
                                <span style={{marginTop: '7px'}}>Enviar correo de prueba a:</span>
                                <div style={{width: '30vh'}}>
                                    <EditText value={correoPrueba} onChange={(e) => setCorreoPrueba(e.target.value)}/>
                                </div>
                                <div style={{marginTop: '7px'}}>
                                    <a href='#' onClick={enviarCorreoPrueba}>Enviar correo de prueba</a>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </>
            }
            {
                (error && errorMessage) &&
                <div style={{marginTop: '10px'}}>
                    <Alert key="danger" variant="danger" onClose={() => {
                        setError(false);
                        setErrorMessage('');
                    }} show={true}
                           style={{fontSize: '0.9em'}}
                           dismissible>
                        {errorMessage}
                    </Alert>
                </div>
            }

            {

                (notification && notificationMessage) &&<div style={{marginTop: '10px'}}>
                    <Alert key="primary" variant="primary" onClose={() => {
                        setNotification(false);
                        setNotificationMessage('');
                    }} show={true}
                           style={{fontSize: '0.9em'}}
                           dismissible>
                        {notificationMessage}
                    </Alert>
                </div>
            }


            {
                (correo) &&
                <>
                    <hr/>
                    <div className='d-flex justify-content-end' style={{marginTop: '10px'}}>
                        <Button size='sm' variant='dark'
                                disabled={(correo && !correo.subject) || uploading}
                                onClick={async () => {
                                    try {
                                        setUploading(true);
                                        const response = await actualizarEvento(props.evento.id, {
                                            id: props.evento.id,
                                            claveEvento: props.evento.claveEvento,
                                            correo: correo,
                                            destinatario: correoPrueba
                                        });
                                        if (response.codigo !== SUCCESS) {
                                            showError(response.mensaje)
                                        }
                                    } catch (err) {
                                        showError('Lo sentimos ha ocurrido un error al intentar guardar la configuración del evento por favor inténtelo más tarde')
                                    } finally {
                                        setUploading(false);
                                    }
                                }}>
                            {
                                uploading ? <>
                                    <label>
                                        Guardando...
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"/>
                                    </label>
                                </> : <> Guardar cambios</>
                            }</Button>
                    </div>
                </>

            }
        </>

    )
}