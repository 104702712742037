import React, {useEffect, useState} from "react";
import {useAccordionButton} from "react-bootstrap/AccordionButton";
import {
    Accordion,
    Alert,
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Form,
    Modal,
    Row,
    Tab,
    Tabs
} from "react-bootstrap";


import {
    formatoFechaTexto, formatoFechaTiempo,
    isAdmin,
    isCalidad,
    isCliente,
    isCoordinador,
    isEnableDownloadDocuments,
    isOperativo,
    isVentas,
    SERVICIO_STATUS_FINALIZADO,
    SUCCESS,
    toDate,
    truncarTexto
} from "../../utils";
import {Actividad, Documento, EncuestaSatisfaccion, Servicio, Usuario} from "../../types/servicio";
import {
    cambiarCoordinador,
    cambiarFechaCapacitacion,
    cambiarFechaInspeccion,
    cambiarInstructor,
    cambiarOperativo,
    cambiarPersonalCalidad,
    guardarEncuestaSatisfaccion
} from "../../services/conem-services";
import ActividadList from "../actividad/ActividadList";
import Loader from "../common/Loader";
import FormEvaluacionSatisfaccion from "./FormEvaluacionSatisfaccion";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import ViewEncuestaSatisfaccion from "./ViewEncuestaSatisfaccion";
import DocumentoItem from "../documento/DocumentoItem";
import Advertencia from "../mensajes/Advertencia";
import FormGeneralEditable from "./FormGeneralEditable";
import LabelFechaEstimada from "../common/LabelFechaEstimada";
import LabelNombreOperativo from "../common/LabelNombreOperativo";

registerLocale('es', es)

// @ts-ignore
function RowCustomToggle({eventKey, children}) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const decoratedOnClick = useAccordionButton(eventKey, () => {
        setIsOpen(!isOpen);
    });

    return (
        <Row onClick={decoratedOnClick} style={{cursor: 'pointer'}}>
            {children}
        </Row>
    );
}

export default function ServicioItem(props: {
    index: number,
    servicio: Servicio,
    calidad: Usuario[],
    coordinadores: Usuario[],
    operadores: Usuario[],
    avance: number,
    actualizar: (servicio: Servicio) => void,
    verificarDocumento: (documento: Documento, verificado: boolean) => void,
    onSelect: () => void,
    updateService: (servicio: Servicio) => void
}) {


    const [servicio, setServicio] = useState<Servicio>(props.servicio);

    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<boolean>(false);

    const [observacionesDocumentacion, setObservacionesDocumentacion] = useState<string>('');

    const [status, setStatus] = useState<string>('');
    const [actividades, setActividades] = useState<Actividad[]>([]);
    const [documentos, setDocumentos] = useState<Documento[]>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const [mostrarEncuestaSatisfaccion, setMostrarEncuestaSatisfaccion] = useState<boolean>(false);
    const [fechaInspeccionSeleccionada, setFechaInspeccionSeleccionada] = useState<Date | null>(toDate(props.servicio.fechaInspeccion));
    const [fechaCapacitacionSeleccionada, setFechaCapacitacionSeleccionada] = useState<Date | null>(toDate(props.servicio.fechaCapacitacion));

    const [coordinadorSeleccionado, setCoordinadorSeleccionado] = useState<Usuario | null>(null);
    const [calidadSeleccionado, setCalidadSeleccionado] = useState<Usuario | null>(null);
    const [operadorSeleccionado, setOperadorSeleccionado] = useState<Usuario | null>(props.servicio.operador);
    const [instructorSeleccionado, setInstructorSeleccionado] = useState<Usuario | null>(props.servicio.instructor);

    const [showModalInstructorExterno, setShowModalInstructorExterno] = useState<boolean>(false);
    const [nombreInstructor, setNombreInstructor] = useState<string>('');
    const [errors, setErrors] = useState<any>({});


    const [calidad, setCalidad] = useState<Usuario[]>([]);
    const [coordinadores, setCoordinadores] = useState<Usuario[]>([]);
    const [operativos, setOperativos] = useState<Usuario[]>([]);

    useEffect(() => {
        setServicio(props.servicio);
        setCoordinadorSeleccionado(props.servicio.coordinador);
        setCalidadSeleccionado(props.servicio.calidad);
        setStatus(props.servicio.status);
        setActividades(props.servicio.actividades);
        setDocumentos(props.servicio.documentos);
        setCalidad(props.calidad.filter((usuario: Usuario) => usuario.grupo?.id === props.servicio.grupo?.id));
        setCoordinadores(props.coordinadores.filter((usuario: Usuario) => usuario.grupo?.id === props.servicio.grupo?.id));
        setOperativos(props.operadores.filter((usuario: Usuario) => (usuario.grupo?.id === props.servicio.grupo?.id)));
        setObservacionesDocumentacion(props.servicio.observacionesDocumentacion ? props.servicio.observacionesDocumentacion : '');

    }, [props.servicio]);

    const showErrorMessage = (message: string) => {
        setError(true);
        setMessage(message);
    }

    const LabelStatusServicio = (status: string) => {
        let estilo = "warning";
        let textStatus = "Próximo a iniciar";
        if (status === 'en_progreso') {
            estilo = "done";
            textStatus = 'En progreso';
        }
        if (status === 'finalizado') {
            estilo = "success";
            textStatus = 'Finalizado';
        }
        return <div className={`conem-label conem-bg-${estilo} rounded`} style={{color: '#3a3939'}}>
            Status del servicio: <span className="fw-bold" style={{fontSize: "0.9em"}}>{textStatus}</span>
        </div>
    }

    const LabelStatusPago = (servicio: Servicio, comprobantesPagos: Documento | null | undefined) => {
        let estilo = "danger";
        let status = '------';
        if (servicio.creditoAutorizado) {
            status = "Crédito autorizado";
        }
        if (servicio.pagoAnticipo) {
            estilo = "warning";
            status = "Pago anticipo";
        }
        if (servicio.pagoLiquidacion) {
            estilo = "success";
            status = "Pago liquidación";
        }


        /*
         if (comprobantesPagos) {
             if (comprobantesPagos.verificado) {
                 estilo = "success";
                 status = "Liquidado";
             } else if (comprobantesPagos.archivos && comprobantesPagos.archivos.length > 0) {
                 estilo = "warning";
                 status = "Verificando comprobantes"
             }
         }*/
        return <div className={`conem-label rounded`} style={{color: '#3a3939'}}>
            <label>Status de pago: <span className="fw-bold" style={{fontSize: "0.9em"}}>{status}</span></label>
        </div>
    }

    const LabelEncuestaSatisfaccion = (servicio: Servicio) => {
        if (servicio.status === 'finalizado') {
            if (servicio.encuestaSatisfaccion) {
                return <div style={{marginLeft: '10px'}}><span className='bi bi-info-circle-fill'
                                                               style={{color: 'rgb(6, 150, 215)'}}></span><span
                    className='folderName' onClick={() => setMostrarEncuestaSatisfaccion(true)}>Ver resultados de la encuesta de satisfacción</span>
                </div>
            } else {
                return <div style={{marginLeft: '10px'}}><span className='bi bi-info-circle-fill'
                                                               style={{color: 'red'}}></span>
                    <span className='folderName' onClick={() => setMostrarEncuestaSatisfaccion(true)}>Realizar la encuesta de satisfacción</span>
                </div>
            }
        }
        return <></>
    }

    const enableCalidad = () => {
        return ((isAdmin() || isVentas()) && status !== SERVICIO_STATUS_FINALIZADO && servicio?.actividades[4]?.status !== 'finalizado');
    }

    const enableCoordinador = () => {
        return ((isAdmin() || isVentas()) && status !== SERVICIO_STATUS_FINALIZADO);
    }

    const enableOperativo = () => {
        return ((isCoordinador() || isAdmin() || isVentas()) && status !== SERVICIO_STATUS_FINALIZADO);
    }

    const enableFechaInspeccionFechaCapacitacion = () => {
        return ((isCoordinador() || isAdmin() || isVentas()) && status !== SERVICIO_STATUS_FINALIZADO && actividades[0]?.status !== 'finalizado');
    }

    const enableInstructor = () => {
        return (status !== SERVICIO_STATUS_FINALIZADO && (isCoordinador() || isOperativo() || isAdmin()));
    }


    const panelBody = () => {
        return <Card.Body>
            <Container>
                <Row>
                    <Col sm='4'>
                        <div className='d-inline-flex'>
                            <label style={{marginRight: '10px'}}>Fecha de inspección:</label>
                            {
                                enableFechaInspeccionFechaCapacitacion() ?
                                    <DatePicker isClearable dateFormat='dd/MM/yyyy HH:mm'
                                                locale="es"
                                                showTimeSelect
                                                selected={fechaInspeccionSeleccionada}
                                                onChange={(date: Date) => {
                                                    setFechaInspeccionSeleccionada(date);
                                                    if (servicio.id) {
                                                        cambiarFechaInspeccion(servicio.id, date).then(resp => {
                                                            if (resp.codigo === SUCCESS) {
                                                                if (props.updateService) {
                                                                    props.updateService(resp.object);
                                                                }
                                                                /*setStatus(resp.object.status);
                                                                const newActividades = cloneDeep(actividades);
                                                                newActividades[0].status = resp.object.actividadInicialStatus;
                                                                newActividades[0].fechaEstimada = resp.object.actividadInicialFechaEstimada;
                                                                setActividades(newActividades);*/
                                                            } else {
                                                                showErrorMessage(resp.mensaje);
                                                            }
                                                        }).catch(err => {
                                                            showErrorMessage("Lo sentimos ocurrió un error al intentar actualizar la fecha de inspección");
                                                        });
                                                    }
                                                }}/> :
                                    (servicio.fechaInspeccion ?
                                        <span
                                            className='fw-bold'>{formatoFechaTiempo(servicio.fechaInspeccion)}</span> :
                                        <span className='fw-bold'>No disponible</span>)
                            }
                        </div>
                    </Col>
                    <Col sm='4'>
                        <div className='d-inline-flex'>
                            <label style={{marginRight: '10px'}}>Fecha de capacitación:</label>
                            {
                                enableFechaInspeccionFechaCapacitacion() ?
                                    <DatePicker isClearable dateFormat='dd/MM/yyyy HH:mm'
                                                showTimeSelect
                                                locale="es" selected={fechaCapacitacionSeleccionada}
                                                onChange={(date: Date) => {
                                                    setFechaCapacitacionSeleccionada(date);
                                                    if (servicio.id) {
                                                        cambiarFechaCapacitacion(servicio.id, date).then(resp => {
                                                            if (resp.codigo === SUCCESS) {
                                                                if (props.updateService) {
                                                                    props.updateService(resp.object);
                                                                }
                                                            } else {
                                                                showErrorMessage(resp.mensaje);
                                                            }
                                                        }).catch(err => {
                                                            showErrorMessage("Lo sentimos ocurrió un error al intentar actualizar la fecha de capacitación");
                                                        });
                                                    }
                                                }}/> :
                                    (servicio.fechaCapacitacion ?
                                        <span
                                            className='fw-bold'>{formatoFechaTiempo(servicio.fechaCapacitacion)}</span> :
                                        <span className='fw-bold'>No disponible</span>)
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-center fs-2"
                             style={{marginTop: '30px', marginBottom: '30px', color: '#3a3939'}}>
                            {
                                status === 'finalizado' && <>
                                                <span>Este servicio finalizó el <span
                                                    className='fw-bold'>{formatoFechaTexto(servicio.fechaFinalizado)}</span></span>
                                </>
                            }
                            <LabelFechaEstimada servicio={servicio}/>
                        </div>
                    </Col>
                </Row>
            </Container>

            {
                (error && message) &&
                <div style={{marginTop: '10px'}}>
                    <Alert key="danger" variant="danger" onClose={() => {
                        setError(false);
                        setMessage(null);
                    }} show={true}
                           style={{fontSize: '0.9em'}}
                           dismissible>
                        {message}
                    </Alert>
                </div>
            }


            <Tabs defaultActiveKey='generales' id='serviceDetailsTab'
                  className='mb-3'>
                <Tab eventKey='generales' className='fs-6' title='Información general'>
                    <Container style={{marginBottom: '20px'}}>
                        <Row>
                            <Col>
                                {
                                    enableOperativo() ? <>
                                        Operativo:
                                        <DropdownButton size="sm" variant="dark"
                                                        title={operadorSeleccionado ? truncarTexto(operadorSeleccionado.nombreCompleto, 30) : 'No asignado'}
                                                        id="dropdownOperador">
                                            {
                                                operativos.map((operativo: Usuario, index: number) => {
                                                    return <Dropdown.Item eventKey={index}
                                                                          onClick={() => {
                                                                              setOperadorSeleccionado(operativo);
                                                                              if (servicio.id && operativo.id) {
                                                                                  cambiarOperativo(servicio.id, operativo.id).then(
                                                                                      resp => {
                                                                                          if (resp.codigo === 200) {
                                                                                              if (props.updateService) {
                                                                                                  props.updateService(resp.object);
                                                                                              }
                                                                                          } else {
                                                                                              showErrorMessage(resp.mensaje);
                                                                                          }
                                                                                      }
                                                                                  ).catch(err => {
                                                                                      showErrorMessage("Lo sentimos no se pudo actualizar al personal operativo")
                                                                                  })
                                                                              }
                                                                          }}>
                                                        {operativo.nombreCompleto}
                                                    </Dropdown.Item>
                                                })
                                            }
                                        </DropdownButton>
                                    </> : <LabelNombreOperativo servicio={servicio}/>
                                }
                            </Col>
                            <Col>
                                {
                                    enableInstructor() ? <>
                                        Instructor:
                                        <DropdownButton size="sm" variant="dark"
                                                        title={instructorSeleccionado ? truncarTexto(instructorSeleccionado.nombreCompleto, 15) : 'No asignado'}
                                                        id="dropdownOperador">
                                            {
                                                operativos.map((operativo: Usuario, index: number) => {
                                                    return <Dropdown.Item eventKey={index}
                                                                          onClick={() => {
                                                                              setInstructorSeleccionado(operativo);
                                                                              if (servicio.id) {
                                                                                  cambiarInstructor(servicio.id, operativo).then(
                                                                                      resp => {
                                                                                          if (resp.codigo === 200) {
                                                                                              if (props.updateService) {
                                                                                                  props.updateService(resp.object);
                                                                                              }
                                                                                          } else {
                                                                                              showErrorMessage(resp.mensaje);
                                                                                          }
                                                                                      }
                                                                                  ).catch(err => {
                                                                                      showErrorMessage("Lo sentimos no se pudo actualizar al instructor")
                                                                                  })
                                                                              }
                                                                          }}>
                                                        {operativo.nombreCompleto}
                                                    </Dropdown.Item>
                                                })
                                            }
                                            <Dropdown.Item onClick={() => {
                                                setNombreInstructor('');
                                                setShowModalInstructorExterno(true);
                                            }}>
                                                Externo...
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </> : <>
                                        <div>Instructor:</div>
                                        <div>
                                        <span
                                            className='fw-bold'>{servicio.instructor ? truncarTexto(servicio.instructor.nombreCompleto, 15) : 'No disponible'}</span>
                                        </div>
                                    </>
                                }
                            </Col>
                        </Row>
                    </Container>
                    <hr/>
                    <FormGeneralEditable servicio={servicio} updateService={props.updateService}/>
                </Tab>
                <Tab eventKey='actividades' title='Actividades'>
                    <Container className='rounded' style={{paddingTop: '10px'}}>
                        <ActividadList servicioId={props.servicio.id}/>
                        {/*

                        <div className="stepper-container">
                            {
                                servicio.actividades?.map((actividad: Actividad) => {

                                    if (actividad.clave === 'revision.calidad' && isCliente()) {
                                        return <></>
                                    } else {
                                        return (
                                            <div className={`step ${actividad.status === 'en_progreso' ? 'active' : (actividad.status === 'finalizado' ? 'completed' : '')}`}>
                                                <div className="v-stepper">
                                                    <div className="stepper-circle"></div>
                                                    <div className="stepper-line"></div>
                                                </div>
                                                <div className='stepper-content'>
                                                    <ActividadItem actividad={actividad}
                                                                   servicioId={servicio.id}
                                                                   showErrorMessage={showErrorMessage}
                                                     updateService={props.updateService}/>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>*/ }
                    </Container>
                </Tab>
                <Tab eventKey='documentos' className='fs-6' title='Documentos' style={{
                    padding: '20px'
                }}>
                    {
                        (status === 'finalizado' && isEnableDownloadDocuments()) &&
                        <div className='d-flex justify-content-end' style={{marginBottom: '20px'}}>
                            <Button size='sm' variant='outline-dark' onClick={() => {
                                setLoading(true);
                                fetch(`/api/servicios/${servicio.id}/documentos`)
                                    .then(response => {
                                        response.blob().then(blob => {
                                            let url = window.URL.createObjectURL(blob);
                                            let a = document.createElement('a');
                                            a.href = url;
                                            a.download = `${servicio.folio}.zip`;
                                            a.click();
                                        });
                                    }).finally(() => {
                                    setLoading(false)
                                });
                            }}>Descargar todos los documentos</Button>
                        </div>
                    }
                    {
                        observacionesDocumentacion && <>
                            <Advertencia showIcon={true} mensaje={observacionesDocumentacion}/>
                            <hr/>
                        </>
                    }
                    {
                        documentos?.map((documento: Documento, index: number) => {
                            return <DocumentoItem documento={documento}
                                                  servicio={servicio}
                                                  showErrorMessage={showErrorMessage}
                                                  onUpdate={(newDocument: Documento) => {
                                                      setDocumentos(documentos.map((d: Documento, i: number) => {
                                                          return i === index ? newDocument : d;
                                                      }))
                                                  }}
                            />
                        })
                    }

                </Tab>
            </Tabs>
        </Card.Body>
    }


    return (
        <>
            <Loader show={loading}/>
            <Card>
                <Card.Header className="servicio-list-item-header">
                    <RowCustomToggle eventKey={`${props.index}`}>
                        <Col className="col-4">
                            <Row>
                                <Col>
                                    <div style={{marginBottom: '5px'}}>
                                        Folio: <span className="fw-bold folderName" style={{fontSize: "14px"}}
                                                     onClick={() => {
                                                         props.onSelect();
                                                     }}>
                                            {servicio.folio}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        LabelStatusServicio(status)
                                    }
                                    {
                                        (isAdmin() || isVentas() || isCliente()) && LabelStatusPago(servicio, documentos[0])
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        (isAdmin() || isVentas() || isCalidad() || isCliente()) && LabelEncuestaSatisfaccion(servicio)
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            {
                                enableCalidad() ?
                                    <>
                                        Calidad:
                                        <DropdownButton variant='dark' size='sm'
                                                        title={calidadSeleccionado ? truncarTexto(calidadSeleccionado.nombreCompleto, 50) : 'No asignado'}
                                                        id="dropdownCoordinador">
                                            {
                                                calidad.map((calidad: Usuario, index: number) => {
                                                    return <Dropdown.Item
                                                        eventKey={index}
                                                        onClick={() => {
                                                            setCalidadSeleccionado(calidad);
                                                            if (servicio.id && calidad.id) {
                                                                cambiarPersonalCalidad(servicio.id, calidad.id).then(
                                                                    resp => {
                                                                        if (resp.codigo === 200) {
                                                                            if (props.updateService) {
                                                                                props.updateService(resp.object);
                                                                            }
                                                                        } else {
                                                                            showErrorMessage(resp.mensaje);
                                                                        }
                                                                    }
                                                                ).catch(err => {
                                                                    showErrorMessage("Lo sentimos no se pudo actualizar al personal de calidad")
                                                                })
                                                            }
                                                        }}>
                                                        {calidad.nombreCompleto}
                                                    </Dropdown.Item>
                                                })
                                            }
                                        </DropdownButton>
                                    </> : <>
                                        <div>Calidad:</div>
                                        <div><span
                                            className='fw-bold'>{servicio.calidad ? truncarTexto(servicio.calidad?.nombreCompleto, 30) : 'No disponible'}</span>
                                        </div>
                                    </>
                            }
                            {
                                enableCoordinador() ?
                                    <>
                                        Coordinador:
                                        <DropdownButton variant='dark' size='sm'
                                                        title={coordinadorSeleccionado ? truncarTexto(coordinadorSeleccionado.nombreCompleto, 30) : 'No asignado'}
                                                        id="dropdownCoordinador">
                                            {
                                                coordinadores.map((coordinador: Usuario, index: number) => {
                                                    return <Dropdown.Item
                                                        eventKey={index}
                                                        onClick={() => {
                                                            setCoordinadorSeleccionado(coordinador);
                                                            if (servicio.id && coordinador.id) {
                                                                cambiarCoordinador(servicio.id, coordinador.id).then(
                                                                    resp => {
                                                                        if (resp.codigo === 200) {
                                                                            if (props.updateService) {
                                                                                props.updateService(resp.object);
                                                                            }
                                                                        } else {
                                                                            showErrorMessage(resp.mensaje);
                                                                        }
                                                                    }
                                                                ).catch(err => {
                                                                    showErrorMessage("Lo sentimos no se pudo actualizar al coordinador")
                                                                })
                                                            }
                                                            setOperativos(props.operadores.filter((usuario: Usuario) => (usuario.coordinador?.id === coordinador.id)));
                                                        }}>
                                                        {coordinador.nombreCompleto}
                                                    </Dropdown.Item>
                                                })
                                            }
                                        </DropdownButton>
                                    </> : <>
                                        <div>Coordinador:</div>
                                        <div><span
                                            className='fw-bold'>{servicio.coordinador ? truncarTexto(servicio.coordinador.nombreCompleto, 30) : 'No disponible'}</span>
                                        </div>
                                    </>
                            }
                        </Col>
                        <Col sm='2'>
                            <div className={`c100 p${props.avance} center`}>
                                <span>{props.avance}%</span>
                                <div className="slice">
                                    <div className="bar"></div>
                                    <div className="fill"></div>
                                </div>
                            </div>
                        </Col>
                    </RowCustomToggle>
                </Card.Header>
                {
                    props.index >= 0 &&
                    <Accordion.Collapse eventKey={`${props.index}`}>
                        {panelBody()}
                    </Accordion.Collapse>
                }
                {
                    props.index === -1 && panelBody()
                }
            </Card>

            <Modal size="lg" className="modal-dialog-centered"
                   show={showModalInstructorExterno}>
                <Modal.Header className='modal-header'>
                    <Modal.Title className='fs-6'>Instructor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form>
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                <Form.Label column sm="3">
                                    Nombre del instructor:
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control isInvalid={!!errors.nombreInstructor} value={nombreInstructor}
                                                  onChange={(e) => setNombreInstructor(e.target.value)}/>
                                    <Form.Control.Feedback
                                        type="invalid">{errors.nombreInstructor}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Form>
                        <div className="d-flex justify-content-end" style={{marginTop: "10px", marginBottom: "10px"}}>
                            <Button size='sm' variant='danger' onClick={() => {
                                setErrors({});
                                setNombreInstructor('');
                                setShowModalInstructorExterno(false);
                            }}>
                                Cancelar
                            </Button>
                            <Button size="sm" variant="dark" onClick={async () => {
                                if (!nombreInstructor || nombreInstructor.trim().length === 0) {
                                    setErrors({nombreInstructor: "Específique un nombre válido"});
                                } else {
                                    const newInstructor: Usuario = {
                                        tipo: 3,
                                        enabled: false,
                                        coordinador: null,
                                        role: '',
                                        correo: nombreInstructor,
                                        nombreCompleto: nombreInstructor ? nombreInstructor : ''
                                    };
                                    if (servicio.id) {
                                        cambiarInstructor(servicio.id, newInstructor).then(
                                            resp => {

                                            }
                                        ).catch(err => {

                                        })
                                    }
                                    setInstructorSeleccionado(newInstructor);
                                    setNombreInstructor('');
                                    setShowModalInstructorExterno(false);
                                }

                            }}>
                                Aceptar
                            </Button>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>

            {
                !servicio.encuestaSatisfaccion &&
                <Modal size="xl" className="modal-dialog-centered"
                       show={mostrarEncuestaSatisfaccion}>
                    <Modal.Header className='modal-header'>
                        <Modal.Title>
                        <span className='fs-6'>
                            Encuesta de satisfacción al cliente - Servicio: {servicio.folio}
                        </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <FormEvaluacionSatisfaccion servicio={servicio}
                                                        cancelar={() => setMostrarEncuestaSatisfaccion(false)}
                                                        guardar={async (e: EncuestaSatisfaccion) => {
                                                            try {
                                                                if (servicio.id) {
                                                                    await guardarEncuestaSatisfaccion(servicio.id, e);
                                                                    servicio.encuestaSatisfaccion = e;
                                                                    setMostrarEncuestaSatisfaccion(false);
                                                                }
                                                            } catch (err) {
                                                                showErrorMessage("Ha ocurrido un error al intentar guardar el cuestionario");
                                                            }
                                                        }}/>
                        </Container>
                    </Modal.Body>
                </Modal>
            }
            {
                servicio.encuestaSatisfaccion &&
                <Modal size="xl" className="modal-dialog-centered"
                       show={mostrarEncuestaSatisfaccion}>
                    <Modal.Header className='modal-header'>
                        <Modal.Title>
                        <span className='fs-6'>
                            Encuesta de satisfacción al cliente - Servicio: {servicio.folio}
                        </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <ViewEncuestaSatisfaccion encuestaSatisfaccion={servicio.encuestaSatisfaccion}
                                                      cerrar={() => setMostrarEncuestaSatisfaccion(false)}/>
                        </Container>
                    </Modal.Body>
                </Modal>
            }
        </>

    )
}