import {Alert, Badge, Button, Col, Container, ListGroup, Pagination, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import PageHeader from "../common/PageHeader";
import UsuarioFiltro from "./UsuarioFiltro";
import {FiltroUsuario, Grupo, UserPagination, Usuario} from "../../types/servicio";
import Loader from "../common/Loader";
import {eliminarUsuario, filtrarUsuarios, restablecerContrasenia, roleText} from "../../services/conem-services";
import {omit} from 'lodash';
import {getUsuarioSesionUsername, SUCCESS} from "../../utils";
import ConfirmacionModal from "../common/ConfirmacionModal";
import UsuarioModal from "./UsuarioModal";
import {useMessage} from "../common/useMessage";

export default function UsuarioList() {

    const message=useMessage();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [usuarios, setUsuarios] = useState<Usuario[]>([]);

    const [usuarioSeleccionado, setUsuarioSeleccionado] = useState<Usuario | null>(null);

    const [loading, setLoading] = useState(false);

    const ROWS_PER_PAGE = 10;
    const [filtro, setFiltro] = useState<FiltroUsuario>({
        rowsPerPage: ROWS_PER_PAGE,
        currentPage: 0,
    });

    const [paginacion, setPaginacion] = useState<UserPagination>({
        numberOfElements: 0,
        totalElements: 0,
        totalPages: 0,
        page: 0,
        items: []
    });

    const [confirmarRestablecerContrasenia, setConfirmarRestablecerContrasenia] = useState<boolean>(false);
    const [confirmarEliminarUsuario, setConfirmarEliminarUsuario] = useState<boolean>(false);

    const aplicarFiltro = async (filtro: FiltroUsuario) => {
        setLoading(true);
        setFiltro(filtro);
        filtrarUsuarios(filtro).then(data => {
            setPaginacion({
                page: data.page,
                items: data.items,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            })
            setUsuarios(data.items);
        }).catch(error => {
            //console.error(error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleChangePage = async (newPage: number) => {
        const newFilter = {...filtro, currentPage: newPage}

        await aplicarFiltro(newFilter);
    };

    useEffect(() => {
        aplicarFiltro({
            rowsPerPage: ROWS_PER_PAGE,
            currentPage: 0,
        }).catch(err => console.log(err));
    }, []);

    const filterLabel = (key: string, value: string, field: string) => {
        return <Button size='sm' variant="secondary"
                       className='tag-item'
                       onClick={async () => {
                           // @ts-ignore
                           const f: FiltroUsuario = omit(filtro, field);
                           await aplicarFiltro(f);
                       }}>
            {key}: <Badge bg="warning" style={{color: 'black'}}>{value}</Badge>
        </Button>
    }

    return (
        <>
            <Loader show={loading}/>
            <Container>
                <PageHeader label="Usuarios"/>
                <Row>
                    <Col>
                        <UsuarioFiltro onFilter={async (userFilter: FiltroUsuario) => {
                            await aplicarFiltro(userFilter);
                        }}/>
                        <Button variant='outline-dark' size='sm' style={{marginLeft: '10px'}} onClick={() => {
                            setUsuarioSeleccionado({
                                tipo: 1,
                                enabled: true,
                                nombreCompleto: '',
                                role: 'ROLE_VENTAS',
                                correo: ''
                            });
                            setShowModal(true);
                        }}>
                            <i className="bi bi-plus"></i> Agregar
                        </Button>
                        <Button variant='outline-dark' size='sm' style={{marginLeft: '10px'}} onClick={async () => {
                            await aplicarFiltro(filtro);
                        }}><i className="bi bi-arrow-repeat"/> Actualizar
                        </Button>
                        <div style={{marginTop: '5px'}}>
                            {
                                filtro.fullName && filterLabel('Nombre', filtro.fullName, 'fullName')
                            }
                            {
                                filtro.email && filterLabel('Correo electrónico', filtro.email, 'email')
                            }
                            {
                                filtro.grupos?.map((g:string)=>{
                                    return filterLabel('Grupo', g, 'grupos')
                                })
                            }

                            {
                                filtro.admins && filterLabel('Rol', 'Administrador', 'admins')
                            }
                            {
                                filtro.managers && filterLabel('Rol', 'Coordinador', 'managers')
                            }
                            {
                                filtro.operators && filterLabel('Rol', 'Operador', 'operators')

                            }
                            {
                                filtro.qas && filterLabel('Rol', 'QA', 'qas')
                            }
                            {
                                filtro.clients && filterLabel('Rol', 'Cliente', 'clients')
                            }
                        </div>
                    </Col>
                    <Col>
                        {
                            paginacion.numberOfElements > 0 &&
                            <div className="d-flex justify-content-end" style={{marginTop: "5px"}}>
                      <span style={{fontSize: "14px"}}>
                             Registros {((paginacion.page) * ROWS_PER_PAGE) + 1} al {((paginacion.page) * ROWS_PER_PAGE) + paginacion.numberOfElements} - Página {paginacion.page + 1} de {paginacion.totalPages} - Total de registros {paginacion.totalElements}
                      </span>
                            </div>
                        }
                    </Col>
                </Row>

                {
                    (message.error && message.show) &&
                    <div style={{marginTop: '10px'}}>
                        <Alert key="danger" variant="danger" onClose={()=>{message.close()}} show={true}
                               style={{fontSize: '0.8em'}}
                               dismissible>
                            {message.message}
                        </Alert>
                    </div>
                }

                <div style={{marginTop: '10px'}}>
                    <div style={{minHeight: '300px'}}>
                        <ListGroup>
                            <ListGroup.Item className="list-header">
                                <Row className="fw-bold">
                                <Col className="col-sm-3">
                                        Nombre completo
                                    </Col>
                                    <Col className="col-sm-3">
                                        Correo electrónico
                                    </Col>
                                    <Col>
                                        Rol
                                    </Col>
                                    <Col>
                                        Grupo
                                    </Col>
                                    <Col sm='2'>
                                        Opciones
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            {
                                usuarios.map((usuario: Usuario) => (
                                    <ListGroup.Item>
                                        <Row>
                                            <Col className="col-sm-3">
                                                <label className='fw-bold folderName' onClick={() => {
                                                    if (usuario.tipo !== 0) {
                                                        setUsuarioSeleccionado(usuario);
                                                        setShowModal(true);
                                                    }
                                                }}>{usuario.nombreCompleto}</label><br/>
                                                {
                                                    usuario.role === 'ROLE_OPERATOR' &&
                                                    <label style={{fontSize: '0.8em'}}>
                                                        Coordinado por: <span
                                                        className='fw-bold'>{usuario.coordinador?.nombreCompleto}</span>
                                                    </label>
                                                }
                                            </Col>
                                            <Col className="col-sm-3">
                                                {usuario.correo}
                                            </Col>
                                            <Col>
                                                <span className='fw-bold'>{roleText(usuario.role ?? '')}</span>
                                            </Col>
                                            <Col>
                                                <span className='fw-bold'>{usuario.grupo?.nombre}</span>
                                            </Col>

                                            <Col sm='2'>
                                                <a href='#' onClick={() => {
                                                    setUsuarioSeleccionado(usuario);
                                                    setConfirmarRestablecerContrasenia(true);
                                                }}>Restablecer contraseña</a>
                                                {
                                                    usuario.correo !== getUsuarioSesionUsername() &&
                                                    <>
                                                        <div style={{width: '20px'}}/>
                                                        <a href='#' onClick={() => {
                                                            setUsuarioSeleccionado(usuario);
                                                            setConfirmarEliminarUsuario(true);
                                                        }}>Eliminar usuario</a>
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))
                            }
                        </ListGroup>
                    </div>
                    <div className="d-flex justify-content-center" style={{marginTop: "10px"}}>
                        <Pagination>
                            {paginacion.totalPages > 1 && Array(paginacion.totalPages).fill(1).map((el, i) =>
                                <Pagination.Item active={i === paginacion.page} onClick={async () => {
                                    if (i !== paginacion.page) {
                                        setLoading(true)
                                        await handleChangePage(i);
                                    }
                                }}>
                                    {i + 1}
                                </Pagination.Item>
                            )}
                        </Pagination>
                    </div>
                </div>
            </Container>
            {
                usuarioSeleccionado &&
                <UsuarioModal show={showModal}
                              usuario={usuarioSeleccionado}
                              onClose={() => setShowModal(false)}
                              onChange={(e: Usuario) => {
                                  aplicarFiltro(filtro);
                                  setShowModal(false)
                              }}/>
            }

            <ConfirmacionModal show={confirmarRestablecerContrasenia} title='Confirmación'
                               onAccept={async () => {
                                   if (usuarioSeleccionado && usuarioSeleccionado.id) {
                                       try {
                                           setLoading(true);
                                           const response = await restablecerContrasenia(usuarioSeleccionado.id);
                                           if (response.codigo === SUCCESS) {
                                               message.showMessage(response.mensaje);
                                           } else {
                                               message.showError(response.mensaje);
                                           }
                                       } catch (err) {
                                           message.showError(`Lo sentimos ha ocurrido un error al intentar actualizar el acceso del usuario ${usuarioSeleccionado.correo}`)
                                       } finally {
                                           setConfirmarRestablecerContrasenia(false);
                                           setLoading(false);
                                       }
                                   }
                               }}
                               onCancel={() => {
                                   setConfirmarRestablecerContrasenia(false);
                               }}
                               message={[`¿Está seguro de restablecer la contraseña del usuario  ${usuarioSeleccionado?.correo}?. Las credenciales se enviarán al correo del usuario`]}/>

            <ConfirmacionModal show={confirmarEliminarUsuario} title='Confirmación'
                               onAccept={async () => {
                                   setLoading(true);
                                   try {
                                       if (usuarioSeleccionado && usuarioSeleccionado.id) {
                                           const response = await eliminarUsuario(usuarioSeleccionado.id);
                                           if (response.codigo === SUCCESS) {
                                               const newListaUsuario = usuarios.filter((item: Usuario) => item.id !== usuarioSeleccionado.id);
                                               setUsuarios(newListaUsuario);
                                               setConfirmarEliminarUsuario(false);
                                           } else {
                                               message.showError(response.mensaje);
                                           }
                                       }
                                   } catch (err) {
                                       message.showError('Lo sentimos ha ocurrido un error al intentar eliminar el usuario');
                                   } finally {
                                       setLoading(false);
                                   }
                               }}
                               onCancel={() => {
                                   setConfirmarEliminarUsuario(false);
                               }}
                               message={[`¿Está seguro de eliminar los accesos del usuario  ${usuarioSeleccionado?.correo}?. Al eliminar los accesos esta operación no se podrá revertir`]}/>
        </>
    )

}
